import React, { useState, useEffect, useRef } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _, { set } from 'lodash'
import './contract-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';
import moment from 'moment'
import * as XLSX from 'xlsx';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { toast } from 'react-toastify';
import { blob } from 'stream/consumers';
import { SubmissionStatus, ReviewStatus, ChooseActionsType, ActivityLkpcd, AdhocChecklistId, CheckList_Type_Lkpcd } from '../../utils/statusConstantValues';
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import Cookies from 'js-cookie';

export default function McoActionsPage() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const [isRemoveFileModalOpen, setRemoveFileModalOpen] = useState(false);
    const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);
    const userType = sessionStorage.getItem('signedInUserType');
    const [removingFileName, setRemovingFileName] = useState('');
    const [frequencyLKPCDList, setFrequencyLKPCDList] = useState<any>([])
    const [businessStatusLKPCDList, setBusinessStatusLKPCDList] = useState<any>([])
    const [afStatusLKPCDList, setAfStatusLKPCDList] = useState<any>([])
    const [reportActivityLKPCDList, setReportActivityLKPCDList] = useState<any>([])
    const [submissionModeList, setSubmissionModeList] = useState<any>([])
    const [actualSbmsnModeLkpcdDropdownList, setActualSbmsnModeLkpcdDropdownList] = useState<any>([])
    const [uploadingFileTypeLKPCDList, setUploadingFileTypeLKPCDList] = useState<any>([])
    const [chooseAnActionLKPCDList, setChooseAnActionLKPCDList] = useState<any>([])
    const { getMasterTableLookupValueData, getMasterTableLookupData } = useLooksUpData();
    const [uploadedFileList, setUploadedFileList] = useState<any>([])
    const [uploadedFileTypeKPCDList, setUploadedFileTypeKPCDList] = useState<any>([])
    const [loadingButton, setLoadingButton] = useState(false);
    const [fileValidateErrors, setFileValidateErrors] = useState([])
    const [isFileValidateModalOpen, setIsFileValidateModalOpen] = useState(false);
    const ReportInventoryService = useReportInventoryService();
    const [activityNotes, setActivityNotes] = useState('');
    const [actualSbmsnModeLkpcdData, setActualSbmsnModeLkpcdData] = useState('');
    const [latestActivityData, setLatestActivityData] = useState<any>(null)
    const [latestActivityUploadFileData, setLatestActivityUploadFileData] = useState<any>()
    const [checklistItems, setChecklistItems] = useState<any>([])
    const [selectedChecklistItemIds, setSelectedChecklistItemIds] = useState<number[]>([]);
    const [chooseActions, setChooseActions] = useState('');
    const [chooseExtendDueDate, setChooseExtendDueDate] = useState('');
    const [V_DB_FLAG, set_V_DB_FLAG] = useState('');
    const [v_SAVE_FLAG, set_v_SAVE_FLAG] = useState('No');
    const [v_CHANGED_ACTION_FLAG, set_v_CHANGED_ACTION_FLAG] = useState('No');
    const [v_CHANGED_DUE_DATE_FLAG, set_v_CHANGED_DUE_DATE_FLAG] = useState('No');
    const [submissionNumber, setSubmissionNumber] = useState(0);
    const [reviewNumber, setReviewNumber] = useState(0);
    const [submissionStatusError, setSubmissionStatusError] = useState('');
    const [fileValue, setFileValue] = useState<FileWithMetadata[]>([]);
    const [startActivityTime, setStartActivityTime] = useState('');
    const [V_REQUESTED_DUE_DATE, set_V_REQUESTED_DUE_DATE] = useState(false);
    const [mcProgramReportDate, setMcProgramReportDate] = useState<any>({});
    const { checkAccess } = useAccessControl();
    const loggedInUserDetails = JSON.parse(Cookies.get('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";

    interface FileWithMetadata {
        id: number;
        fileName: string;
        uploadTypeLkpcd: string;
        uploaded: boolean;
        file: File | String;
        blobName?: string;
    }

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcActualSbmsnModeLkpcdRes = response.data.filter(
                    (dt: any) => dt.lkpcd_group === 'AD' && dt.table_name === 'MC_PROGRAM_REPORT' && dt.column_name === 'REPORT_SBMSN_MODE_LKPCD'
                )

                const mcActualSbmsnModeLkpcdFilter = mcActualSbmsnModeLkpcdRes.map((code: any) => {
                    return {
                        value: `${code.lkpdmn_lkpcd}`,
                        label: code.lkdmn_lkpcd_desc,
                        lkpcd_group: code.lkpcd_group,
                    }
                })
                setActualSbmsnModeLkpcdDropdownList(mcActualSbmsnModeLkpcdFilter);

                const mcBusinessStatusLKPCD = filterAndMapData('NG', 'MCT_REPORT_INSTANCE', 'LAST_SUBMSN_STATUS_LKPCD');
                const mcFrequencyLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'DUE_DATE_FREQUENCY_LKPCD');
                const mcAfStatusLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'AF_STATUS_LKPCD');
                const mcSubmisstionMode = filterAndMapData('AD', 'MC_PROGRAM_REPORT', 'REPORT_SBMSN_MODE_LKPCD');
                const mcReportActivityList = filterAndMapData('NG', 'MCT_REPORT_ACTIVITY', 'ACTIVITY_LKPCD');

                const mcUploadingFileTypeList = filterAndMapData('MC', 'MCT_REPORT_ATTACHMENT', 'UPLOAD_TYPE_LKPCD');
                const mcChooseAnActionLKPCD = filterAndMapData('MC', 'MCT_REPORT_ACTIVITY', 'DECISION_ACTION_LKPCD');

                const filteredChooseActionDropdownData = mcChooseAnActionLKPCD.filter((code: any) => {
                    // switch (recordData?.last_submission_status) {
                    //     case '05': // Not Scheduled
                    //         return false;
                    //     case '10': // Scheduled
                    //         return true;
                    //     case '15': // Past Due
                    //         return code.lkpdmn_lkpcd === 'SB' || code.lkpdmn_lkpcd === 'ET';
                    //     case '20': // Extension requested
                    //         return code.lkpdmn_lkpcd === 'SB';
                    //     case '25': // Rescheduled
                    //         return false;
                    //     case '30': // Submitted
                    //         return code.lkpdmn_lkpcd === 'SB' || code.lkpdmn_lkpcd === 'RO';
                    //     case '35': // Resubmitted
                    //         return code.lkpdmn_lkpcd === 'SB';
                    //     case '40': // Reopen requested
                    //         return code.lkpdmn_lkpcd === 'SB';
                    //     case '50': // Closed
                    //         return false;
                    //     default:
                    //         return false;
                    // }

                    if (((recordData?.last_submission_status === SubmissionStatus.RESCHEDULED || recordData?.last_submission_status === SubmissionStatus.PAST_DUE) && (recordData?.last_review_status === null || (recordData?.last_review_status === ''))) ||
                        ((recordData?.last_submission_status === SubmissionStatus.EXTENSION_REQUESTED || recordData?.last_submission_status === SubmissionStatus.REOPEN_REQUESTED) && (recordData?.last_review_status === ReviewStatus.ACCEPTED) || (recordData?.last_review_status === ReviewStatus.ADDITIONAL_INFO_REQUESTED))
                    ) {
                        return code.lkpdmn_lkpcd === ChooseActionsType.SUBMIT;
                    } else if (recordData?.last_submission_status === SubmissionStatus.SCHEDULED && (recordData?.last_review_status === null || (recordData?.last_review_status === ''))) {
                        return code.lkpdmn_lkpcd === ChooseActionsType.SUBMIT || code.lkpdmn_lkpcd === ChooseActionsType.EXTEND;
                    }
                    else if ((recordData?.last_submission_status === SubmissionStatus.SUBMITTED || recordData?.last_submission_status === SubmissionStatus.RESUBMITTED) && recordData?.last_review_status === ReviewStatus.ACCEPTED) {
                        return code.lkpdmn_lkpcd === ChooseActionsType.REOPEN
                    } else if (detailsPageReferenceFalg === true) {
                        return true;
                    }
                    else {
                        return setSubmissionStatusError('Currently no action can be taken as the report instance is not in ideal status. Please contact application administrator to resolve this issue')
                    }
                });

                const filterAndMapAsDropdownData = filteredChooseActionDropdownData.map((code: any) => {
                    return {
                        value: `${code.lkpdmn_lkpcd}`,
                        label: code.lkdmn_lkpcd_desc,
                        lkpcd_group: code.lkpcd_group,
                    }
                })

                setFrequencyLKPCDList(mcFrequencyLKPCD);
                setBusinessStatusLKPCDList(mcBusinessStatusLKPCD);
                setAfStatusLKPCDList(mcAfStatusLKPCD);
                setSubmissionModeList(mcSubmisstionMode);
                setReportActivityLKPCDList(mcReportActivityList);
                setUploadingFileTypeLKPCDList(mcUploadingFileTypeList);
                setChooseAnActionLKPCDList(filterAndMapAsDropdownData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchUploadedFilesList = async (id: any) => {
        try {
            setIsLoading(true);
            const attachmentsResponse = await ReportInventoryService.mctReportAttachments(id);
            const blobFilesResponse = await ReportInventoryService.getUploadedFilesListBySid('mcofiles', 'NEMCIS-' + id);

            if (attachmentsResponse && blobFilesResponse) {
                const attachments = attachmentsResponse.data;
                const blobFiles = blobFilesResponse.data;

                const combinedResponse = attachments.map((attachment: any) => {
                    const matchingBlobFile = blobFiles.find((blobFile: any) => parseInt(blobFile.attachmentRecordId) === attachment.mctReportAttachmentSid);
                    return {
                        ...attachment,
                        ...matchingBlobFile,
                        file: matchingBlobFile ? matchingBlobFile.blobName : attachment.fileName
                    };
                });

                setUploadedFileList(combinedResponse);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLatestAvtivity = async (id: any) => {
        try {
            const activitysResponse = await ReportInventoryService.getLatestActivityByInstanceId(id);

            if (activitysResponse) {
                const activity = activitysResponse.data;

                if (activity.submitFlag === "N" && (activity.activityLkpcd === ActivityLkpcd.ACTIVITY_140 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_150 ||
                    activity.activityLkpcd === ActivityLkpcd.ACTIVITY_240 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_270
                    || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_301 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_310
                    || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_341 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_345
                )) {
                    set_V_DB_FLAG('U');
                    setLatestActivityData(activity);

                    const attachmentResponse = await ReportInventoryService.getLAttachmentByActivityId(activity.mctReportActivitySid);
                    const mctReportChecklistresponse = await ReportInventoryService.getReportChecklistByActivityId(activity.mctReportActivitySid);

                    if (attachmentResponse) {

                        // const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + recordData?.mct_report_instance_sid,
                        //     attachmentResponse.data[0].mctReportAttachmentSid);
                        // if (response) {
                        //     console.log('response', response);
                        // }

                        const existingFiles = attachmentResponse.data.map((file: any) => ({
                            id: file.mctReportAttachmentSid,
                            fileName: file.fileName,
                            uploadTypeLkpcd: file.uploadTypeLkpcd,
                            uploaded: true,
                            file: file.fileName,
                        }));
                        setFileValue(existingFiles);
                    }

                    if (mctReportChecklistresponse) {
                        const savedChecklistItemIds = mctReportChecklistresponse.data.map((item: any) => item.checklistItemId);
                        setSelectedChecklistItemIds(savedChecklistItemIds);
                    }
                }
                else if (activity.submitFlag === "Y" && (activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_140 || activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_150 ||
                    activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_240 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_270 || activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_301 ||
                    activity.activityLkpcd === ActivityLkpcd.ACTIVITY_310 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_341 || activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_345
                )) {
                    set_V_DB_FLAG('I');
                    setLatestActivityData(activity);
                }

            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchLatestAvtivityWithoutAttachment = async (id: any) => {
        try {
            const activitysResponse = await ReportInventoryService.getLatestActivityByInstanceId(id);

            if (activitysResponse) {
                const activity = activitysResponse.data;

                if (activity.submitFlag === "N" && (activity.activityLkpcd === ActivityLkpcd.ACTIVITY_140 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_150 ||
                    activity.activityLkpcd === ActivityLkpcd.ACTIVITY_240 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_270
                    || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_305 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_310
                    || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_345
                )) {
                    set_V_DB_FLAG('U');
                    setLatestActivityData(activity);
                }
                else if (activity.submitFlag === "Y" && (activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_140 || activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_150 ||
                    activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_240 || activity.activityLkpcd === ActivityLkpcd.ACTIVITY_270 || activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_305 ||
                    activity.activityLkpcd === ActivityLkpcd.ACTIVITY_310 || activity.activityLkpcd !== ActivityLkpcd.ACTIVITY_345
                )) {
                    set_V_DB_FLAG('I');
                    setLatestActivityData(activity);
                }
                return activity;
            }
        } catch (e) {
            console.error(e);
        }
    };

    const deleteUploadedFile = async (blobName: any) => {
        try {
            const request = {
                "containerName": "mcofiles",
                "blobName": blobName
            }
            const response = await ReportInventoryService.deleteUploadedFile(request);
            if (response.status === 200) {
                toast.success('File deleted successfully');
            }

        } catch (e) {
            console.error(e);
            toast.error('Error while file deleting');
        }
    }

    const updatedUploadedFileStatus = async (attachmentRecordId: any) => {
        try {
            const request = {
                attachmentRecordId: attachmentRecordId,
                updatedBy: accounts[0]?.username || '',
                updatedDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
            };
            const response = await ReportInventoryService.updatedUploadedFileStatus(request);
            if (response.status === 200) {
                toast.success('File status updated successfully');
            }
        } catch (e) {
            console.error(e);
            toast.error('Error while updating file status');
        }
    }

    const deleteActivityAndAttchament = async (activityId: any) => {
        try {
            const response = await ReportInventoryService.deleteActivityAndAttchament(activityId);
            if (response.status === 200) {
                toast.success('Last activity deleted successfully');
            }

        } catch (e) {
            console.error(e);
            toast.error('Error while deleting last saved activity');
        }
    }

    const updateactivitychecklistattachments = async (mctActivityInstanceSid: any) => {
        try {
            setIsLoading(true);
            const response = await ReportInventoryService.updateactivitychecklistattachments(mctActivityInstanceSid, accounts[0]?.username || '', moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'));
            if (response.status === 200) {
                toast.success('Last saved activity successfully updated');
            }

        } catch (e) {
            console.error(e);
            toast.error('Error while updating last activity');
        } finally {
            setIsLoading(false);
        }
    }

    const [recordData, setRecordData] = useState((location?.state as LocationState)?.recordData || null);

    const mapApiResponseToLocalState = (apiData: any) => {
        return {
            mct_report_instance_sid: apiData.mctReportInstanceSid,
            plan_id: apiData.planId,
            program_report_sid: apiData.programReportSid,
            period_begin_date: apiData.periodBeginDate,
            period_end_date: apiData.periodEndDate,
            latest_due_date: apiData.latestDueDate,
            current_due_date: apiData.acceptedDueDate || apiData.requestedDueDate || apiData.originalDueDate,
            original_due_date: apiData.originalDueDate,
            current_submission_number: apiData.currentSubmissionNumber,
            last_submission_status: apiData.lastSubmsnStatusLkpcd,
            last_submission_date: apiData.lastSubmsnDate,
            last_validation_status: apiData.lastValidationStatusLkpcd,
            last_review_status: apiData.lastReviewStatusLkpcd,
            notes_summary: apiData.notesSummary,
            oprtnl_flag: apiData.oprtnlFlag,
            created_by: apiData.createdBy,
            created_date: apiData.createdDate,
            updated_by: apiData.updatedBy,
            updated_date: apiData.updatedDate,
            plan_name: apiData.planName,
            report_name: apiData.reportName,
            start_reference_number: apiData.stateReferenceNumber,
            uploadFiles: apiData.uploadFiles,
            reporting_type_lkpcd: apiData.reportingTypeLkpcd,
            report_sbmsn_mode_lkpcd: apiData.reportSbmsnModeLkpcd,
            due_date_frequency_lkpcd: apiData.dueDateFrequencyLkpcd,
            actual_sbmsnmode_Lkpcd: apiData.actualSbmsnModeLkpcd,
        };
    };

    const getMctreportinstanceDetailsById = async (id: any) => {
        try {
            const response = await ReportInventoryService.getMctreportinstanceDetailsById(id);
            if (response) {
                const { data } = response;
                const mappedData = mapApiResponseToLocalState(data);
                setRecordData(mappedData);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getProgramReportByProgramReportSid = async (id: any) => {
        try {
            if (id) {
                const response = await ReportInventoryService.getProgramReportByProgramReportSid(id);

                if (response) {
                    const { data } = response;
                    setMcProgramReportDate(data);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getCheckListItemsByReportId = async (id: any) => {
        try {
            if (!recordData?.program_report_sid) {
                const response = await ReportInventoryService.getCheckListItemByCheckListTypeId(AdhocChecklistId.CHECKLIST_ID_21, CheckList_Type_Lkpcd.MSBA)
                if (response) {
                    const { data } = response;
                    setChecklistItems(data);
                }
            }
            else {
                if (recordData?.program_report_sid) {
                    const response = await ReportInventoryService.getCheckListItemsByProgramReportSid(id, CheckList_Type_Lkpcd.MSBL);
                    if (response) {
                        const { data } = response;
                        setChecklistItems(data);
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    useEffect(() => {
        const fetchData = async () => {
            await fetchGetMasterTableLookupValueData();
            if (recordData?.mct_report_instance_sid) {
                await fetchUploadedFilesList(recordData.mct_report_instance_sid);
                await fetchLatestAvtivity(recordData.mct_report_instance_sid);
                await getCheckListItemsByReportId(recordData.program_report_sid);
                await getProgramReportByProgramReportSid(recordData?.program_report_sid);
            }
        };

        setIsLoading(true);
        fetchData();
        setActualSbmsnModeLkpcdData(recordData?.actual_sbmsnmode_Lkpcd || '');
        setIsLoading(false);
    }, [recordData]);

    interface ContractReport {
        mct_report_instance_sid: string;
        plan_id: string;
        program_report_sid: string;
        period_begin_date: string;
        period_end_date: string;
        current_due_date: string;
        original_due_date: string;
        current_submission_number: string;
        last_submission_status: string;
        last_submission_date: string;
        last_validation_status: string;
        last_review_status: string;
        latest_due_date: string;
        notes_summary: string;
        oprtnl_flag: string;
        created_by: string;
        created_date: string;
        updated_by: string;
        updated_date: string;
        plan_name: string;
        report_name: string;
        start_reference_number: string;
        uploadFiles: string;
        reporting_type_lkpcd: string;
        report_sbmsn_mode_lkpcd: string;
        due_date_frequency_lkpcd: string;
        actual_sbmsnmode_Lkpcd: string;
    };

    interface LocationState {
        recordData?: ContractReport;
        reviewHistoryData?: any;
        detailsPageReferenceFalg?: boolean;
    }

    // const recordData = (location?.state as LocationState)?.recordData || null;
    const reviewHistoryData = (location?.state as LocationState)?.reviewHistoryData || null;
    const detailsPageReferenceFalg = (location?.state as LocationState)?.detailsPageReferenceFalg || false;

    function handleCancel() {
        history.goBack()
    }

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleChecklistItemChange = (checklistItemId: number) => {
        setSelectedChecklistItemIds(prevSelected => {
            if (prevSelected.includes(checklistItemId)) {
                // Remove the checklistItemId if it is already selected
                return prevSelected.filter(id => id !== checklistItemId);
            } else {
                // Add the checklistItemId if it is not selected
                return [...prevSelected, checklistItemId];
            }
        });
    };

    useEffect(() => {
        // setSelectedChecklistItemIds(selectedChecklistItemIds)
    }, [selectedChecklistItemIds]);

    useEffect(() => {
        const start = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        setStartActivityTime(start);
    }, []);

    //step - 5
    useEffect(() => {
        if (V_DB_FLAG === 'I') {
            if (chooseActions === ChooseActionsType.SUBMIT && recordData?.last_review_status !== ReviewStatus.ADDITIONAL_INFO_REQUESTED
                && v_CHANGED_ACTION_FLAG === 'No') {
                setSubmissionNumber(latestActivityData?.submissionNumber + 1);
                setReviewNumber(1)
            } else {
                setSubmissionNumber(Number(recordData?.current_submission_number) || 0);
                if (recordData?.last_review_status === ReviewStatus.ADDITIONAL_INFO_REQUESTED
                    && v_CHANGED_ACTION_FLAG === 'No') {
                    setReviewNumber(latestActivityData?.reviewNumber + 1);
                } else {
                    setReviewNumber(latestActivityData?.reviewNumber);
                }
            }
        }
    }, [chooseActions, latestActivityData]);

    useEffect(() => {
        if (V_DB_FLAG === 'U') {
            setSubmissionNumber(latestActivityData?.submissionNumber);
            setReviewNumber(latestActivityData?.reviewNumber);
            if (latestActivityData?.acceptedDueDate) {
                setChooseExtendDueDate(formatDateForInput(latestActivityData?.acceptedDueDate));
                set_V_REQUESTED_DUE_DATE(true)
            }
        }
    }, [V_DB_FLAG]);

    useEffect(() => {
        if (latestActivityData && latestActivityData?.submitFlag === 'N' && chooseActions !== latestActivityData?.decisionActionLkpcd) {
            set_v_CHANGED_ACTION_FLAG('No');
            const userConfirmed = window.confirm('Changing action will delete all the data for currently saved action. Do you want to continue changing it?');
            if (userConfirmed) {
                set_v_CHANGED_ACTION_FLAG('Yes');
                //refesh the page
                try {
                    // deleteActivityAndAttchament(latestActivityData?.mctReportActivitySid);
                    updateactivitychecklistattachments(latestActivityData?.mctReportActivitySid);
                    setLatestActivityData(null);
                    setFileValue([])
                    // if (uploadedFileList.length > 0) {
                    //     uploadedFileList.forEach((file: any) => {
                    //         const matchingFile = fileValue.find(f => f.id === parseInt(file.attachmentRecordId, 10));
                    //         console.log('matchingFile', matchingFile);
                    //         if (matchingFile) {
                    //             deleteUploadedFile(file.blobName);
                    //             setFileValue([])
                    //         }
                    //     });
                    // }

                    set_V_DB_FLAG('I');

                    return;
                } catch (e) {
                    console.error(e);
                    toast.error('Error while deleting last saved activity');
                    return;
                } finally {
                    setLoadingButton(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    fetchLatestAvtivity(recordData?.mct_report_instance_sid)
                    return;
                }
            } else {
                setLoadingButton(false);
                // return;
            }
        }
    }, [chooseActions]);

    useEffect(() => {
        if (latestActivityData) {
            if (latestActivityData?.submitFlag === 'N') {
                setChooseActions(latestActivityData?.decisionActionLkpcd);
                setActivityNotes(latestActivityData?.activityNotes);
                setSubmissionNumber(latestActivityData?.submissionNumber);
                setReviewNumber(latestActivityData?.reviewNumber);
                set_V_DB_FLAG('U');
            }
            if (detailsPageReferenceFalg === true) {
                setSubmissionNumber(reviewHistoryData?.submissionNumber);
                setChooseActions(reviewHistoryData?.decisionActionLkpcd);
            }
        }
    }, [latestActivityData]);

    useEffect(() => {
        if (recordData) {
            const uploadedFiles = Array.isArray(recordData.uploadFiles) ? recordData.uploadFiles : [];
            setFileValue(uploadedFiles);
        }
    }, [recordData]);

    const mimeTypeMapping: { [key: string]: string } = {
        'PDF': 'application/pdf',
        'JPEG': 'image/jpeg',
        'PNG': 'image/png',
        'TXT': 'text/plain',
        'XLSX': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'XLS': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    async function handleSaveButton(isSubmit: boolean) {
        setLoadingButton(true);
        const formData = new FormData();
        for (const file of fileValue) {
            if (typeof file.file !== 'string') {
                formData.append('files', file.file as File); // Ensure it's a File object
            } else {
                // Fetch the actual file blob using the blobName
                try {
                    const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + recordData?.mct_report_instance_sid, file.id);
                    const blob = response.data;
                    const mimeType = mimeTypeMapping[file.uploadTypeLkpcd] || 'application/octet-stream';
                    const existingFile = new File([blob], file.fileName, { type: mimeType });
                    formData.append('files', existingFile);
                } catch (error) {
                    console.error(`Error fetching file blob for ${file.fileName}:`, error);
                    setLoadingButton(false);
                    return;
                }
            }
        }

        const saveAttachmentVo: {
            mctReportInstanceSid: string | undefined;
            mcoViewableFlag: string;
            submissionModeLkpcd: string;
            decisionActionLkpcd: string;
            uploadTypeLkpcd: string[];
            createdBy: string;
            updatedBy: string;
            createdDate: string;
            updatedDate: string;
            activityStartDateTime: string;
            activityEndDateTime: string;
            isRequestForSubmit: boolean;
            isRequestForSave: boolean;
            activityNotes: string;
            checkListItems: number[];
            extendDueDate: string;
            reviewNumber: number;
            submissionNumber: number | undefined;
            activityLkpcd?: string;
            submsnStatus?: string;
            reviewStatusLkpcd?: string | null;
            updateExistingActivity?: boolean;
            activityId?: number;
        } = {
            "mctReportInstanceSid": recordData?.mct_report_instance_sid,
            "mcoViewableFlag": "Y",
            "submissionModeLkpcd": "W",
            "decisionActionLkpcd": chooseActions,
            "uploadTypeLkpcd": fileValue.map(file => file.uploadTypeLkpcd),
            "createdBy": accounts[0]?.username || '',
            "updatedBy": accounts[0]?.username || '',
            "createdDate": moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            "updatedDate": moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            "activityStartDateTime": startActivityTime,
            "activityEndDateTime": moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            "isRequestForSubmit": isSubmit,
            "isRequestForSave": !isSubmit,
            "activityNotes": activityNotes,
            "checkListItems": selectedChecklistItemIds,
            "extendDueDate": chooseExtendDueDate,
            "reviewNumber": reviewNumber,
            "submissionNumber": submissionNumber
        };

        if (V_DB_FLAG === 'I') {
            saveAttachmentVo.updateExistingActivity = false;
            if (chooseActions === ChooseActionsType.SUBMIT && recordData?.last_review_status !== ReviewStatus.ADDITIONAL_INFO_REQUESTED) {
                if (submissionNumber === 1) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_140;
                    saveAttachmentVo.submsnStatus = SubmissionStatus.SUBMITTED;
                    saveAttachmentVo.reviewStatusLkpcd = recordData?.last_review_status;
                    saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.SUBMIT;
                } else if ((submissionNumber ?? 0) > 1) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_150;
                    saveAttachmentVo.submsnStatus = SubmissionStatus.RESUBMITTED;
                    saveAttachmentVo.reviewStatusLkpcd = recordData?.last_review_status;
                    saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.SUBMIT;
                }
            } else if (chooseActions === ChooseActionsType.SUBMIT && recordData?.last_review_status === ReviewStatus.ADDITIONAL_INFO_REQUESTED) {
                if (recordData?.last_submission_status === SubmissionStatus.SUBMITTED || recordData?.last_submission_status === SubmissionStatus.RESUBMITTED) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_240;
                } else if (recordData?.last_submission_status === SubmissionStatus.EXTENSION_REQUESTED) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_301;
                } else if (recordData?.last_submission_status === SubmissionStatus.REOPEN_REQUESTED) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_341;
                } else {
                    toast.error("It seems the report instance statuses are not set up correctly. Please contact the application administrator to resolve this issue.");
                    throw new Error("It seems the report instance statuses are not set up correctly. Please contact the application administrator to resolve this issue.");
                }
                saveAttachmentVo.submsnStatus = recordData?.last_submission_status;
                saveAttachmentVo.reviewStatusLkpcd = ReviewStatus.ADDITIONAL_INFO_SUBMITTED;
                saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.SUBMIT;
            } else if (chooseActions === ChooseActionsType.EXTEND) {
                saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_270;
                saveAttachmentVo.submsnStatus = SubmissionStatus.EXTENSION_REQUESTED;
                saveAttachmentVo.reviewStatusLkpcd = null;
                saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.EXTEND;
            } else if (chooseActions === ChooseActionsType.REOPEN) {
                saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_310;
                saveAttachmentVo.submsnStatus = SubmissionStatus.REOPEN_REQUESTED;
                saveAttachmentVo.reviewStatusLkpcd = null;
                saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.REOPEN;
            } else {
                toast.error("There are unexpected actions that are allowed for MCO Action currently. Contact Application Administrator to resolve this issue.");
                throw new Error("There are unexpected actions that are allowed for MCO Action currently. Contact Application Administrator to resolve this issue.");
            }

            saveAttachmentVo.activityId = latestActivityData?.mctReportActivitySid;

            const attachmentBlob = new Blob([JSON.stringify(saveAttachmentVo)], { type: 'application/json' });
            formData.append('saveMcoVo', attachmentBlob);

            setLoadingButton(true)
            ReportInventoryService.saveMcoActions(formData)
                .then((response) => {
                    if (response.status === 200) {
                        const validationErrors = response.data.filter(
                            (file: any) => file.validationStatus === "-1" || file.validationStatus === "1" || file.validationStatus === "2" && file.valid === false
                        );

                        if (validationErrors.length > 0) {
                            const hasSpecificError = validationErrors.some((fileError: any) =>
                                fileError.errors.some((error: any) =>
                                    "File name patterns failed".includes(error.ruleErrorMessage)
                                    || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                    || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                    || "Another user has worked on this report instance already.".includes(error.ruleErrorMessage)
                                )
                            );

                            if (hasSpecificError) {
                                const specificErrorMessage = validationErrors
                                    .flatMap((fileError: any) => fileError.errors)
                                    .find((error: any) =>
                                        "File name patterns failed".includes(error.ruleErrorMessage)
                                        || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                        || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                        || "Another user has worked on this report instance already.".includes(error.ruleErrorMessage)
                                    )?.ruleErrorMessage;

                                toast.error(specificErrorMessage || "An error occurred.");
                            }
                            else {
                                setFileValidateErrors(validationErrors);
                                setIsFileValidateModalOpen(true);
                            }
                        } else {
                            history.push('/contact-report-invenroty');
                        }
                    }
                    else if (response.status === 413) {
                        toast.error(response.data.error);
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                })
                .finally(() => {
                    setLoadingButton(false);
                });
        } else if (V_DB_FLAG === 'U') {
            // Update last Activity record in MCT_REPORT_ACTIVITY
            // const updatedActivity = {
            //     ...latestActivityData,
            //     activityNotes: activityNotes,
            //     updateExistingActivity: true,
            //     updatedBy: accounts[0]?.username || '',
            //     updatedDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
            // };
            saveAttachmentVo.updateExistingActivity = true;
            saveAttachmentVo.updatedBy = accounts[0]?.username || '';
            saveAttachmentVo.updatedDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
            saveAttachmentVo.activityId = latestActivityData?.mctReportActivitySid;

            const attachmentBlob = new Blob([JSON.stringify(saveAttachmentVo)], { type: 'application/json' });
            formData.append('saveMcoVo', attachmentBlob);

            setLoadingButton(true)
            ReportInventoryService.saveMcoActions(formData)
                .then((response) => {
                    if (response.status === 200) {
                        const validationErrors = response.data.filter(
                            (file: any) => file.validationStatus === "-1" || file.validationStatus === "1" || file.validationStatus === "2" && file.valid === false
                        );

                        if (validationErrors.length > 0) {
                            const hasSpecificError = validationErrors.some((fileError: any) =>
                                fileError.errors.some((error: any) =>
                                    error.ruleErrorMessage.includes('File name patterns failed')
                                    || error.ruleErrorMessage.includes("Last activity's submit status is not 'Y'")
                                    || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                    || error.ruleErrorMessage.includes("Another user has worked on this report instance already.")
                                )
                            );

                            if (hasSpecificError) {
                                const specificError = validationErrors
                                    .flatMap((fileError: any) => fileError.errors)
                                    .find((error: any) =>
                                        error.ruleErrorMessage.includes('File name patterns failed')
                                        || error.ruleErrorMessage.includes("Last activity's submit status is not 'Y'")
                                        || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                        || error.ruleErrorMessage.includes("Another user has worked on this report instance already.")
                                    );

                                if (specificError) {
                                    const fileError = validationErrors.find((fileError: any) =>
                                        fileError.errors.some((error: any) => error.ruleErrorMessage === specificError.ruleErrorMessage)
                                    );

                                    if (fileError && specificError.ruleErrorMessage.includes('File name patterns failed')) {
                                        toast.error(`File name with ${fileError.fileName} is not matching with required filename templates. Please correct the name and upload the file.`);
                                    } else {
                                        toast.error(specificError.ruleErrorMessage || "An error occurred.");
                                    }
                                }
                            } else {
                                setFileValidateErrors(validationErrors);
                                setIsFileValidateModalOpen(true);
                            }
                        } else {
                            history.push('/contact-report-invenroty');
                        }

                    }
                })
                .catch((error) => {
                    console.log('error', error);
                })
                .finally(() => {
                    setLoadingButton(false);
                });


            // const updatedActivity = {
            //     ...latestActivityData,
            //     activityNotes: activityNotes,
            //     updatedBy: accounts[0]?.username || '',
            //     updatedDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
            // };

            // attachmentVo.updatedBy = accounts[0]?.username || '';
            // attachmentVo.updatedDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');

            // try {
            //     await ReportInventoryService.updateActivity(updatedActivity);
            //     toast.success('Activity updated successfully');
            // } catch (error) {
            //     console.error('Error updating activity:', error);
            //     toast.error('Failed to update activity');
            // } finally {
            //     setLoadingButton(false);
            // }
        }
    }

    async function handleSubmitButton(isSubmit: boolean) {
        setLoadingButton(true);
        const saveFormData = new FormData();
        const submitFormData = new FormData();
        for (const file of fileValue) {
            if (typeof file.file !== 'string') {
                saveFormData.append('files', file.file as File); // Ensure it's a File object
                submitFormData.append('files', file.file as File); // Ensure it's a File object
            } else {
                // Fetch the actual file blob using the blobName
                try {
                    const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + recordData?.mct_report_instance_sid, file.id);
                    const blob = response.data;
                    const mimeType = mimeTypeMapping[file.uploadTypeLkpcd] || 'application/octet-stream';
                    const existingFile = new File([blob], file.fileName, { type: mimeType });
                    saveFormData.append('files', existingFile);
                    submitFormData.append('files', existingFile);
                } catch (error) {
                    console.error(`Error fetching file blob for ${file.fileName}:`, error);
                    setLoadingButton(false);
                    return;
                }
            }
        }

        if (V_DB_FLAG === 'I') {
            const saveAttachmentVo: {
                mctReportInstanceSid: string | undefined;
                mcoViewableFlag: string;
                submissionModeLkpcd: string;
                decisionActionLkpcd: string;
                uploadTypeLkpcd: string[];
                createdBy: string;
                updatedBy: string;
                createdDate: string;
                updatedDate: string;
                activityStartDateTime: string;
                activityEndDateTime: string;
                isRequestForSubmit: boolean;
                isRequestForSave: boolean;
                activityNotes: string;
                checkListItems: number[];
                extendDueDate: string;
                reviewNumber: number;
                submissionNumber: number | undefined;
                activityLkpcd?: string;
                submsnStatus?: string;
                reviewStatusLkpcd?: string | null;
                updateExistingActivity?: boolean;
                activityId?: number;
            } = {
                "mctReportInstanceSid": recordData?.mct_report_instance_sid,
                "mcoViewableFlag": "Y",
                "submissionModeLkpcd": "W",
                "decisionActionLkpcd": chooseActions,
                "uploadTypeLkpcd": fileValue.map(file => file.uploadTypeLkpcd),
                "createdBy": accounts[0]?.username || '',
                "updatedBy": accounts[0]?.username || '',
                "createdDate": moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                "updatedDate": moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                "activityStartDateTime": startActivityTime,
                "activityEndDateTime": moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                "isRequestForSubmit": false,
                "isRequestForSave": true,
                "activityNotes": activityNotes,
                "checkListItems": selectedChecklistItemIds,
                "extendDueDate": chooseExtendDueDate,
                "reviewNumber": reviewNumber,
                "submissionNumber": submissionNumber
            };

            saveAttachmentVo.updateExistingActivity = false;
            if (chooseActions === ChooseActionsType.SUBMIT && recordData?.last_review_status !== ReviewStatus.ADDITIONAL_INFO_REQUESTED) {
                if (submissionNumber === 1) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_140;
                    saveAttachmentVo.submsnStatus = SubmissionStatus.SUBMITTED;
                    saveAttachmentVo.reviewStatusLkpcd = recordData?.last_review_status;
                    saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.SUBMIT;
                } else if ((submissionNumber ?? 0) > 1) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_150;
                    saveAttachmentVo.submsnStatus = SubmissionStatus.RESUBMITTED;
                    saveAttachmentVo.reviewStatusLkpcd = recordData?.last_review_status;
                    saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.SUBMIT;
                }
            } else if (chooseActions === ChooseActionsType.SUBMIT && recordData?.last_review_status === ReviewStatus.ADDITIONAL_INFO_REQUESTED) {
                if (recordData?.last_submission_status === SubmissionStatus.SUBMITTED || recordData?.last_submission_status === SubmissionStatus.RESUBMITTED) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_240;
                } else if (recordData?.last_submission_status === SubmissionStatus.EXTENSION_REQUESTED) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_301;
                } else if (recordData?.last_submission_status === SubmissionStatus.REOPEN_REQUESTED) {
                    saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_341;
                } else {
                    toast.error("It seems the report instance statuses are not set up correctly. Please contact the application administrator to resolve this issue.");
                    throw new Error("It seems the report instance statuses are not set up correctly. Please contact the application administrator to resolve this issue.");
                }
                saveAttachmentVo.submsnStatus = recordData?.last_submission_status;
                saveAttachmentVo.reviewStatusLkpcd = ReviewStatus.ADDITIONAL_INFO_SUBMITTED;
                saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.SUBMIT;
            } else if (chooseActions === ChooseActionsType.EXTEND) {
                saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_270;
                saveAttachmentVo.submsnStatus = SubmissionStatus.EXTENSION_REQUESTED;
                saveAttachmentVo.reviewStatusLkpcd = null;
                saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.EXTEND;
            } else if (chooseActions === ChooseActionsType.REOPEN) {
                saveAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_310;
                saveAttachmentVo.submsnStatus = SubmissionStatus.REOPEN_REQUESTED;
                saveAttachmentVo.reviewStatusLkpcd = null;
                saveAttachmentVo.decisionActionLkpcd = ChooseActionsType.REOPEN;
            } else {
                toast.error("There are unexpected actions that are allowed for MCO Action currently. Contact Application Administrator to resolve this issue.");
                throw new Error("There are unexpected actions that are allowed for MCO Action currently. Contact Application Administrator to resolve this issue.");
            }

            saveAttachmentVo.activityId = latestActivityData?.mctReportActivitySid;

            const attachmentBlob = new Blob([JSON.stringify(saveAttachmentVo)], { type: 'application/json' });
            saveFormData.append('saveMcoVo', attachmentBlob);

            try {
                setLoadingButton(true)
                const saveResponse = await ReportInventoryService.saveMcoActions(saveFormData)
                if (saveResponse.status === 200) {
                    const validationErrors = saveResponse.data.filter(
                        (file: any) => file.validationStatus === "-1" || file.validationStatus === "1" || file.validationStatus === "2" && file.valid === false
                    );

                    if (validationErrors.length > 0) {
                        const hasSpecificError = validationErrors.some((fileError: any) =>
                            fileError.errors.some((error: any) =>
                                "File name patterns failed".includes(error.ruleErrorMessage)
                                || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                || "Another user has worked on this report instance already.".includes(error.ruleErrorMessage)
                            )
                        );

                        if (hasSpecificError) {
                            const specificErrorMessage = validationErrors
                                .flatMap((fileError: any) => fileError.errors)
                                .find((error: any) =>
                                    "File name patterns failed".includes(error.ruleErrorMessage)
                                    || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                    || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                    || "Another user has worked on this report instance already.".includes(error.ruleErrorMessage)
                                )?.ruleErrorMessage;

                            toast.error(specificErrorMessage || "An error occurred.");
                            return;
                        }
                        else {
                            setFileValidateErrors(validationErrors);
                            setIsFileValidateModalOpen(true);
                        }
                    } else {
                        setLatestActivityData(null);
                        const latestActivityData = await fetchLatestAvtivityWithoutAttachment(recordData?.mct_report_instance_sid);
                        setLatestActivityData(latestActivityData);

                        handleSubmitWithLatestData(isSubmit, latestActivityData);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('Failed to save or submit actions');
            } finally {
                setLoadingButton(false);
            }
        } else {
            handleSubmitWithLatestData(isSubmit, latestActivityData);
        }
    }

    const handleSubmitWithLatestData = async (isSubmit: boolean, latestActivityData: any) => {
        setLoadingButton(true);
        const submitFormData = new FormData();
        for (const file of fileValue) {
            if (typeof file.file !== 'string') {
                submitFormData.append('files', file.file as File); // Ensure it's a File object
            } else {
                // Fetch the actual file blob using the blobName
                try {
                    const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + recordData?.mct_report_instance_sid, file.id);
                    const blob = response.data;
                    const mimeType = mimeTypeMapping[file.uploadTypeLkpcd] || 'application/octet-stream';
                    const existingFile = new File([blob], file.fileName, { type: mimeType });
                    submitFormData.append('files', existingFile);
                } catch (error) {
                    console.error(`Error fetching file blob for ${file.fileName}:`, error);
                    setLoadingButton(false);
                    return;
                }
            }
        }
        // Additional logic for submit
        const submitAttachmentVo: {
            mctReportInstanceSid: string | undefined;
            mcoViewableFlag: string;
            submissionModeLkpcd: string;
            decisionActionLkpcd: string;
            uploadTypeLkpcd: string[];
            createdBy: string;
            updatedBy: string;
            createdDate: string;
            updatedDate: string;
            activityStartDateTime: string;
            activityEndDateTime: string;
            isRequestForSubmit: boolean;
            isRequestForSave: boolean;
            activityNotes: string;
            checkListItems: number[];
            extendDueDate: string;
            reviewNumber: number;
            submissionNumber: number | undefined;
            activityLkpcd?: string;
            submsnStatus?: string;
            reviewStatusLkpcd?: string | null;
            submitFlag?: string;
            activityDueDate?: string;
            updateExistingActivity?: boolean;
            activityId?: number;
            actualSbmsnModeLkpcd?: string;
        } = {
            "mctReportInstanceSid": recordData?.mct_report_instance_sid,
            "mcoViewableFlag": "Y",
            "submissionModeLkpcd": "W",
            "decisionActionLkpcd": chooseActions,
            "uploadTypeLkpcd": fileValue.map(file => file.uploadTypeLkpcd),
            "createdBy": accounts[0]?.username || '',
            "updatedBy": accounts[0]?.username || '',
            "createdDate": moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            "updatedDate": moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            "activityStartDateTime": startActivityTime,
            "activityEndDateTime": moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            "isRequestForSubmit": isSubmit,
            "isRequestForSave": !isSubmit,
            "activityNotes": activityNotes,
            "checkListItems": selectedChecklistItemIds,
            "extendDueDate": chooseExtendDueDate,
            "reviewNumber": reviewNumber,
            "submissionNumber": submissionNumber,
            "actualSbmsnModeLkpcd": actualSbmsnModeLkpcdData
        };

        if (chooseActions === ChooseActionsType.SUBMIT && latestActivityData?.reviewStatusLkpcd !== ReviewStatus.ADDITIONAL_INFO_SUBMITTED) {
            if (submitAttachmentVo.submissionModeLkpcd === 'W' || submitAttachmentVo.submissionModeLkpcd === 'B') {
                submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_153;
                submitAttachmentVo.reviewStatusLkpcd = ReviewStatus.VIRUS_SCAN;
                submitAttachmentVo.submitFlag = 'Y';
            } else {
                const reportMasterCatalog = await ReportInventoryService.getProgramReportByProgramReportSid(recordData?.mct_report_instance_sid);
                const { reportFileTypeLkpcd, autoValidationFlag, reportingContentTypeLkpcd } = reportMasterCatalog.data;

                if (reportFileTypeLkpcd === 'XLS' && autoValidationFlag === 'Y' && reportingContentTypeLkpcd === 'S') {
                    submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_160;
                    submitAttachmentVo.reviewStatusLkpcd = ReviewStatus.IN_INITIAL_VALIDATION;
                    submitAttachmentVo.submitFlag = 'Y';
                } else {
                    submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_180;
                    submitAttachmentVo.reviewStatusLkpcd = ReviewStatus.IN_REVIEW;
                    submitAttachmentVo.submitFlag = 'N';
                }
            }
            submitAttachmentVo.submsnStatus = latestActivityData?.submsnStatusLkpcd;
            submitAttachmentVo.decisionActionLkpcd = '';
        } else if (chooseActions === ChooseActionsType.SUBMIT && latestActivityData?.reviewStatusLkpcd === ReviewStatus.ADDITIONAL_INFO_SUBMITTED) {
            const uploadedFilesCount = fileValue.length;

            if (uploadedFilesCount > 0) {
                if (latestActivityData?.submsnStatusLkpcd === SubmissionStatus.SUBMITTED || latestActivityData?.submsnStatusLkpcd === SubmissionStatus.RESUBMITTED) {
                    if (recordData?.reporting_type_lkpcd === 'A') {
                        submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_240;
                    } else {
                        submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_243;
                    }
                } else if (latestActivityData?.submsnStatusLkpcd === SubmissionStatus.EXTENSION_REQUESTED) {
                    submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_303;
                } else if (latestActivityData?.submsnStatusLkpcd === SubmissionStatus.REOPEN_REQUESTED) {
                    submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_343;
                } else {
                    toast.error("It seems the activity statuses are not set up correctly. Please contact the application administrator to resolve this issue.");
                    throw new Error("It seems the activity statuses are not set up correctly. Please contact the application administrator to resolve this issue.");
                }
                submitAttachmentVo.reviewStatusLkpcd = ReviewStatus.ADDITIONAL_INFO_REQUESTED_VIRUS_SCAN;
                submitAttachmentVo.submitFlag = 'Y';
            } else {
                if (latestActivityData?.submsnStatusLkpcd === SubmissionStatus.SUBMITTED || latestActivityData?.submsnStatusLkpcd === SubmissionStatus.RESUBMITTED) {
                    submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_250;
                } else if (latestActivityData?.submsnStatusLkpcd === SubmissionStatus.EXTENSION_REQUESTED) {
                    submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_308;
                } else if (latestActivityData?.submsnStatusLkpcd === SubmissionStatus.REOPEN_REQUESTED) {
                    submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_350;
                } else {
                    toast.error("It seems the activity statuses are not set up correctly. Please contact the application administrator to resolve this issue.");
                    throw new Error("It seems the activity statuses are not set up correctly. Please contact the application administrator to resolve this issue.");
                }
                submitAttachmentVo.reviewStatusLkpcd = ReviewStatus.ADDITIONAL_INFO_SUBMITTED;
                submitAttachmentVo.submitFlag = 'N';
            }
            submitAttachmentVo.submsnStatus = latestActivityData?.submsnStatusLkpcd;
            submitAttachmentVo.decisionActionLkpcd = '';
        } else if (chooseActions === ChooseActionsType.EXTEND) {
            const uploadedFilesCount = fileValue.length;

            if (uploadedFilesCount > 0) {
                submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_273;
                submitAttachmentVo.reviewStatusLkpcd = ReviewStatus.VIRUS_SCAN;
                submitAttachmentVo.submitFlag = 'Y';
            } else {
                submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_280;
                submitAttachmentVo.reviewStatusLkpcd = ReviewStatus.IN_REVIEW;
                submitAttachmentVo.submitFlag = 'Y';
            }
            submitAttachmentVo.submsnStatus = latestActivityData?.submsnStatusLkpcd;
            submitAttachmentVo.decisionActionLkpcd = '';
        } else if (chooseActions === ChooseActionsType.REOPEN) {
            const uploadedFilesCount = fileValue.length;

            if (uploadedFilesCount > 0) {
                submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_313;
                submitAttachmentVo.reviewStatusLkpcd = ReviewStatus.VIRUS_SCAN;
                submitAttachmentVo.submitFlag = 'Y';
            } else {
                submitAttachmentVo.activityLkpcd = ActivityLkpcd.ACTIVITY_320;
                submitAttachmentVo.reviewStatusLkpcd = ReviewStatus.IN_REVIEW;
                submitAttachmentVo.submitFlag = 'Y';
            }
            submitAttachmentVo.submsnStatus = latestActivityData?.submsnStatusLkpcd;
            submitAttachmentVo.decisionActionLkpcd = '';
        } else {
            toast.error("There are unexpected actions that are allowed for MCO Action currently. Contact Application Administrator to resolve this issue.");
            throw new Error("There are unexpected actions that are allowed for MCO Action currently. Contact Application Administrator to resolve this issue.");
        }

        if (submitAttachmentVo.reviewStatusLkpcd === ReviewStatus.IN_REVIEW || submitAttachmentVo.reviewStatusLkpcd === ReviewStatus.ADDITIONAL_INFO_SUBMITTED) {
            submitAttachmentVo.activityDueDate = moment().add(5, 'days').format('YYYY-MM-DD');
        } else {
            submitAttachmentVo.activityDueDate = moment().format('YYYY-MM-DD');
        }

        submitAttachmentVo.activityId = latestActivityData?.mctReportActivitySid;
        const submitAttachmentBlob = new Blob([JSON.stringify(submitAttachmentVo)], { type: 'application/json' });
        submitFormData.append('sbmitMcoVo', submitAttachmentBlob);

        setLoadingButton(true);
        try {
            const submitResponse = await ReportInventoryService.submitMcoActions(submitFormData)
            if (submitResponse.status === 200) {
                const validationErrors = submitResponse.data.filter(
                    (file: any) => file.validationStatus === "-1" || file.validationStatus === "1" || file.validationStatus === "2" && file.valid === false
                );

                if (validationErrors.length > 0) {
                    const hasSpecificError = validationErrors.some((fileError: any) =>
                        fileError.errors.some((error: any) =>
                            "File name patterns failed".includes(error.ruleErrorMessage)
                            || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                            || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                            || "Another user has worked on this report instance already.".includes(error.ruleErrorMessage)
                        )
                    );

                    if (hasSpecificError) {
                        const specificErrorMessage = validationErrors
                            .flatMap((fileError: any) => fileError.errors)
                            .find((error: any) =>
                                "File name patterns failed".includes(error.ruleErrorMessage)
                                || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                || "Another user has worked on this report instance already.".includes(error.ruleErrorMessage)
                            )?.ruleErrorMessage;

                        toast.error(specificErrorMessage || "An error occurred.");
                    } else {
                        setFileValidateErrors(validationErrors);
                        setIsFileValidateModalOpen(true);
                    }
                } else {
                    history.push('/contact-report-invenroty');
                }
            }
        }
        catch (error) {
            console.error('Error:', error);
            toast.error('Failed to save or submit actions');
        } finally {
            setLoadingButton(false);
        }
    };

    const handleFileUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map(file => ({
                id: Date.now(),
                fileName: file.name,
                uploadTypeLkpcd: '',
                uploaded: true,
                file: file // Store the actual file object
            }));
            setFileValue(prevFiles => {
                // Merge new files with existing files, avoiding duplicates
                const existingFiles = prevFiles.filter(file => !newFiles.some(newFile => newFile.fileName === file.fileName));
                return [...existingFiles, ...newFiles];
            });
        }
    };

    const handleFileTypeChange = (e: any, fileId: any) => {
        const { value } = e.target;
        const isRFSelected = value === 'RF';
        const isCSSelected = value === 'CS';
        const isAFSelected = value === 'AF';
        const isRFAlreadySelected = fileValue.some((file) => file.uploadTypeLkpcd === 'RF' && file.id !== fileId);
        const isCurrentFileRF = fileValue.find((file) => file.id === fileId)?.uploadTypeLkpcd === 'RF';
        const isLastRF = fileValue.filter((file) => file.uploadTypeLkpcd === 'RF').length === 1;
        const afCount = fileValue.filter((file) => file.uploadTypeLkpcd === 'AF').length;

        // Check if RF is already selected for another file
        if (isRFSelected && isRFAlreadySelected) {
            toast.warn('One Report File already selected.');
            return;
        }

        if (chooseActions === ChooseActionsType.SUBMIT) {
            {
                // Check if the current file is the last RF and the user is trying to deselect RF
                if (!isRFSelected && isCurrentFileRF && isLastRF) {
                    toast.warn('Must have the Report File');
                    return;
                }

                // Check if no file has RF selected and the user is trying to select another type
                if (!isRFSelected && !isRFAlreadySelected && !isCurrentFileRF) {
                    toast.warn('Must have the Report File');
                    return;
                }
                if (mcProgramReportDate) {
                    // Check if Certification Statement File is selected and mcProgramReportDate.certificationReqdFlag is not Y
                    if (isCSSelected && mcProgramReportDate.certificationReqdFlag !== 'Y') {
                        toast.warn('Certification Statement File type not allow.');
                        return;
                    }

                    // Check if Associated File is selected and mcProgramReportDate.associatedFilesCount is reached
                    if (isAFSelected && afCount >= mcProgramReportDate.associatedFilesCount) {
                        toast.warn('Associated File not allowed.');
                        return;
                    }
                }
            }
        }

        const updatedFiles = fileValue.map((file) =>
            file.id === fileId ? { ...file, uploadTypeLkpcd: value || file.uploadTypeLkpcd } : file
        );
        setFileValue(updatedFiles);
    }

    const handleRemoveFile = (fileId: string) => {
        setFileValue(prevFiles => prevFiles.filter(f => f.id !== Number(fileId)));
    };

    const handleRichTextChangeNotes = (value: any) => {
        setActivityNotes(value)
    }

    function handleGeneralInputData(event: any) {
        const { name, value } = event.target
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const handleChooseActionsDropdownData = (event: any) => {
        const { name, value } = event.target
        setChooseActions(value)
    }

    const handleActualSbmsnModeDropdownData = (event: any) => {
        const { name, value } = event.target
        setActualSbmsnModeLkpcdData(value)
    }
    const handleExtendDueDate = (event: any) => {
        const { name, value } = event.target;
        const formattedDate = moment(value).format('YYYY-MM-DD');
        setChooseExtendDueDate(formattedDate);
    }
    const formatDateForInput = (value: any) => {
        if (value) {
            const date = moment(value);
            return date.isValid() ? date.format('YYYY-MM-DD') : '';
        }
        return '';
    };

    const formatDateAndTime = (value: any) => {
        if (value) {
            const date = moment(value);
            return date.isValid() ? date.format('M/D/YY h:mm A') : '';
        }
        return '';
    };

    const viewUploadedDocs = (event: any) => {
        const documentUrl = '/assets/docs/ScreenMockUp-ManagedCareCollabTool.pdf';
        window.open(documentUrl, '_blank');
    }

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingFrequencyLkpcd = (data: any) => getMatchingDescription(frequencyLKPCDList, data);
    const matchingBusinessStatusLkpcd = (data: any) => getMatchingDescription(businessStatusLKPCDList, data);
    const matchingAfStatusLkpcd = (data: any) => getMatchingDescription(afStatusLKPCDList, data);
    const matchingSubmissionModelLkpcd = (data: any) => getMatchingDescription(submissionModeList, data);
    const matchingreportActivityLKPCD = (data: any) => getMatchingDescription(reportActivityLKPCDList, data);
    const matchingUploadedFileTypeLKPCD = (data: any) => getMatchingDescription(uploadingFileTypeLKPCDList, data);

    const openRemoveFileModal = (fileId: any) => {
        setFileIdToDelete(fileId);
        setRemoveFileModalOpen(true);
        setRemovingFileName(fileValue.find(f => f.id === Number(fileId))?.fileName || '');
    };

    const closeRemoveFileModal = () => {
        setRemoveFileModalOpen(false);
        setFileIdToDelete(null);
        setRemovingFileName('')
    };

    const closeFileValidateModal = () => {
        setIsFileValidateModalOpen(false);
        history.goBack()
    };

    const confirmDelete = () => {
        if (fileIdToDelete !== null) {
            console.log('fileIdToDelete', fileIdToDelete);
            uploadedFileList.forEach((file: any) => {
                if (file.attachmentRecordId === fileIdToDelete) {
                    updatedUploadedFileStatus(fileIdToDelete)
                }
            })

            handleRemoveFile(fileIdToDelete);

            // uploadedFileList.forEach((file: any) => {
            //     if (file.attachmentRecordId === fileIdToDelete) {
            //         deleteUploadedFile(file.blobName);
            //     }
            //     if (fileIdToDelete !== null) {
            //         handleRemoveFile(fileIdToDelete);
            //     }
            // })
        } else {
            if (fileIdToDelete !== null) {
                handleRemoveFile(fileIdToDelete);
            }
        }

        closeRemoveFileModal();
    };

    const handleExportToExcel = () => {
        const data: { FileName: string; ErrorId: string; ErrorType: string; SheetName: string; Column: string; RowNum: string; ErrorMessage: string }[] = [];

        fileValidateErrors.forEach((fileError: any) => {
            fileError.errors.forEach((error: any) => {
                data.push({
                    FileName: fileError.fileName,
                    ErrorId: error.errId,
                    ErrorType: error.errType,
                    SheetName: error.sheetName,
                    Column: error.column,
                    RowNum: error.rowNum,
                    ErrorMessage: error.ruleErrorMessage,
                });
            });
        });

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Errors');

        XLSX.writeFile(workbook, 'FileValidationErrors.xlsx');
    };

    const maskUpdatedBy = (value: any) => {
        if (!value) return '';
        const visiblePart = value.slice(0, 2);
        const maskedPart = '*'.repeat(value.length - 2);
        return `${visiblePart}${maskedPart}`;
    };


    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="MCO's Actions" />
                </div>
            </div>

            <div className="form-layout">
                {/* <p>{submissionStatusError}</p> */}
                <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                    <Button
                        className="OkButton"
                        disabled={detailsPageReferenceFalg ? true : false || (fileValue.length > 0 && (fileValue.some(file => !file.uploadTypeLkpcd)))
                            || activityNotes === '' || chooseActions === '' || selectedChecklistItemIds === null || selectedChecklistItemIds.length === 0 ||
                            !checkAccess('mco_actions_page', 'FULL')
                        }
                        onClick={() => handleSaveButton(false)}
                        loading={loadingButton}
                    >
                        Save
                    </Button>
                    <Button
                        className="OkButton"
                        disabled={detailsPageReferenceFalg ? true : false || latestActivityData?.latestActivityData === 'N' ? false : (fileValue.length > 0 && (fileValue.some(file => !file.uploadTypeLkpcd)))
                            || activityNotes === '' || chooseActions === '' || selectedChecklistItemIds === null || selectedChecklistItemIds.length === 0 ||
                            !checkAccess('mco_actions_page', 'FULL')
                        }
                        onClick={() => handleSubmitButton(true)}
                        loading={loadingButton}
                    >
                        Submit
                    </Button>
                    <Button
                        className="OkButton"
                        // disabled={detailsPageReferenceFalg ? true : false}
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                </div>

                <div className="details-block">
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="MCO Name"
                                name="plan_name"
                                value={recordData?.plan_name}
                                onChange={handleGeneralInputData}
                                disabled={true}

                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Report Name"
                                name="report_name"
                                value={recordData?.report_name}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Frequency"
                                name="due_date_frequency_lkpcd"
                                value={matchingFrequencyLkpcd(recordData?.due_date_frequency_lkpcd)}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <InputText
                                label="Period"
                                name="plan_name"
                                value={recordData?.period_begin_date + ' - ' + recordData?.period_end_date}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="row1">
                        <div className="element" >
                            <div className="usa-form-group">
                                <FormInput
                                    label="Due Date"
                                    type="date"
                                    name="latest_due_date"
                                    value={formatDateForInput(recordData?.latest_due_date)}
                                    onChange={handleGeneralInputData}
                                    placeholder={'MM/DD/YYYY'}
                                    id={''}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Submission Channel"
                                name="report_sbmsn_mode_lkpcd"
                                value={matchingSubmissionModelLkpcd(recordData?.report_sbmsn_mode_lkpcd)}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Current Submission"
                                name="current_submission_number"
                                placeholder="current Submission"
                                value={submissionNumber}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {isLoading ? (
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <p>Loading.....</p>
                        </div>
                    ) :
                        <>
                            <div className="details-block">
                                <div className="flex items-center space-x-4">
                                    <div className="element">
                                        {submissionStatusError && (
                                            <div style={{ color: 'red' }}>
                                                <p>{submissionStatusError}</p>
                                            </div>
                                        )}
                                        <Dropdown
                                            choices={chooseAnActionLKPCDList}
                                            emptyChoiceLabel="Select action"
                                            name="decisionActionLkpcd"
                                            label="Choose an action"
                                            data-testid="payment_type_testid"
                                            value={chooseActions}
                                            onChange={handleChooseActionsDropdownData}
                                            mandatoryLabel={true}
                                            disabled={detailsPageReferenceFalg ? true : false}
                                        />
                                    </div>
                                    {chooseActions === ChooseActionsType.EXTEND && (
                                        <div className="element usa-form-group">
                                            <FormInput
                                                label="Extend Due Date to"
                                                type="date"
                                                name="extendDueDate"
                                                value={chooseExtendDueDate}
                                                onChange={handleExtendDueDate}
                                                placeholder={'MM-DD-YYYY'}
                                                id={''}
                                                required={true}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="element">
                                    <Dropdown
                                        choices={actualSbmsnModeLkpcdDropdownList}
                                        emptyChoiceLabel="Select Channel"
                                        name="actualSbmsnModeLkpcd"
                                        label="Actual Submission Channel"
                                        data-testid="actualSbmsnModeLkpcd"
                                        value={actualSbmsnModeLkpcdData || ''}
                                        onChange={handleActualSbmsnModeDropdownData}
                                    />
                                </div>

                                <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                    <label style={{ marginBottom: '10px' }}>
                                        <b>Add Notes for the State Reviewer:<span className="usa-mandatory-label">
                                            *
                                        </span></b>
                                    </label>
                                    <ReactQuill
                                        style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                                        value={activityNotes}
                                        className={detailsPageReferenceFalg ? "greyed-out" : ''}
                                        readOnly={detailsPageReferenceFalg ? true : false}
                                        onChange={handleRichTextChangeNotes}
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline', 'strike'],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                                [{ align: [] }],
                                                ['clean'],
                                            ],
                                        }}
                                    />

                                </div>

                                {!detailsPageReferenceFalg && (
                                    <>
                                        <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                            {/* <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                                        <Button
                                            className="OkButton"
                                            onClick={handleFileUploadButtonClick}
                                        >
                                            Upload File
                                        </Button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </div> */}
                                            <label style={{ marginBottom: '10px' }}>
                                                <b>Attachments, if any required:</b>
                                            </label>

                                            <div className="element">
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                                        <thead>
                                                            <tr style={{ backgroundColor: '#d3d3d3' }}>
                                                                <th style={{ border: '1px solid black' }}>No</th>
                                                                <th style={{ border: '1px solid black' }}>File Name</th>
                                                                <th style={{ border: '1px solid black' }}>File Type</th>
                                                                <th style={{ border: '1px solid black' }}>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ alignContent: 'center' }}>
                                                            <tr>
                                                                <td colSpan={4} style={{ border: '1px solid black', textAlign: 'center' }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5px', marginTop: '-5px' }}>
                                                                        <Button
                                                                            className="choose-file-button"
                                                                            disabled={!checkAccess('mco_actions_page', 'FULL')}
                                                                            onClick={handleFileUploadButtonClick}                                                                >
                                                                            Add an Attachment
                                                                        </Button>
                                                                        <input
                                                                            type="file"
                                                                            ref={fileInputRef}
                                                                            style={{ display: 'none' }}
                                                                            onChange={handleFileChange}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            {fileValue.length > 0 ? (
                                                                fileValue.map((file, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ border: '1px solid black', fontSize: '14px' }}>{index + 1}</td>
                                                                        <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.fileName}</td>
                                                                        <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                                            {file.uploaded ? (
                                                                                <div>
                                                                                    <select
                                                                                        value={file.uploadTypeLkpcd || ""}
                                                                                        onChange={(e) => handleFileTypeChange(e, file.id)}
                                                                                    >
                                                                                        <option value="">Select File Type</option>
                                                                                        {uploadingFileTypeLKPCDList.map((item: any) => (
                                                                                            <option key={item.lkpdmn_lkpcd} value={item.lkpdmn_lkpcd}>
                                                                                                {item.lkdmn_lkpcd_desc}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                    <span className="usa-mandatory-label" style={{ marginLeft: '5px', color: 'red' }}>*</span>
                                                                                </div>
                                                                            ) : (
                                                                                matchingUploadedFileTypeLKPCD(file.uploadTypeLkpcd)
                                                                            )}
                                                                        </td>
                                                                        <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                                            {file.fileName && (
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => openRemoveFileModal(file.id.toString())}
                                                                                    style={{ border: '1px solid red', borderRadius: '5px', padding: '5px' }}
                                                                                >
                                                                                    Delete
                                                                                </button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={4} style={{ border: '1px solid black', textAlign: 'center', fontSize: '14px' }}>No files available</td>
                                                                </tr>
                                                            )}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <Modal
                                            isOpen={isRemoveFileModalOpen}
                                            onRequestClose={closeRemoveFileModal}
                                            contentLabel="Confirm Remove"
                                            style={{
                                                content: {
                                                    width: '500px',
                                                    height: '200px',
                                                    margin: 'auto',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    border: '1px solid red',
                                                },
                                            }}
                                        >
                                            <h2><b style={{ fontSize: '20px' }}>Confirm Remove</b></h2>
                                            <p style={{ marginTop: '10px', fontSize: '18px', }}>Are you sure you want to delete this file?</p>
                                            <p style={{ marginTop: '10px' }}>{removingFileName}</p>
                                            <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                                                <button
                                                    className='usa-button'
                                                    onClick={confirmDelete}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    className='usa-button'
                                                    onClick={closeRemoveFileModal}
                                                >
                                                    No
                                                </button>
                                            </div>
                                        </Modal>

                                        <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                            <label style={{ marginBottom: '10px' }}>
                                                <b>Checklist:</b>
                                            </label>

                                            <div className="element">
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                                        <thead>
                                                            <tr style={{ backgroundColor: '#d3d3d3' }}>
                                                                <th style={{ border: '1px solid black' }}>Select</th>
                                                                <th style={{ border: '1px solid black' }}>Checklist Item</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ alignContent: 'center' }}>
                                                            {checklistItems.map((item: any) => (
                                                                <tr key={item.checklistItemId}>
                                                                    <td style={{ border: '1px solid black' }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            style={{ margin: '5px', transform: 'scale(1.5)' }}
                                                                            checked={selectedChecklistItemIds.includes(item.checklistItemId)}
                                                                            onChange={() => handleChecklistItemChange(item.checklistItemId)}
                                                                        />
                                                                    </td>
                                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{item.itemDescription}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    }

                    <Modal
                        isOpen={isFileValidateModalOpen}
                        onRequestClose={closeFileValidateModal}
                        contentLabel="File Validation Errors"
                        style={{
                            content: {
                                width: '80%',
                                height: '80%',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                border: '1px solid red',
                                overflow: 'hidden',
                            },
                        }}
                    >
                        <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                            <h2><b style={{ fontSize: '20px' }}>File validation errors</b></h2>
                            <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%', marginBottom: '10px' }}>
                                <button
                                    disabled={fileValidateErrors.length === 0}
                                    className='usa-button'
                                    onClick={handleExportToExcel}
                                >
                                    Download Excel
                                </button>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#d3d3d3' }}>
                                            <th style={{ border: '1px solid black' }}>Error Id</th>
                                            <th style={{ border: '1px solid black' }}>Error Type</th>
                                            <th style={{ border: '1px solid black' }}>Sheet Name</th>
                                            <th style={{ border: '1px solid black' }}>Column</th>
                                            <th style={{ border: '1px solid black' }}>Row</th>
                                            <th style={{ border: '1px solid black' }}>Error Message</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ alignContent: 'center' }}>
                                        {fileValidateErrors.length > 0 ? (
                                            fileValidateErrors.map((fileError: any, fileIndex: any) => (
                                                <React.Fragment key={fileIndex}>
                                                    <tr>
                                                        <td colSpan={6} style={{ border: '1px solid black', fontSize: '14px', fontWeight: 'bold' }}>
                                                            {`File Name: ${fileError.fileName}`}
                                                        </td>
                                                    </tr>
                                                    {fileError.errors.map((error: any, errIndex: any) => (
                                                        <tr key={errIndex}>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.errId}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.errType}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.sheetName}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.column}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.rowNum}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.ruleErrorMessage}</td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6} style={{ border: '1px solid black', fontSize: '14px', textAlign: 'center' }}>
                                                    No validation errors found.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                            <button
                                className='usa-button'
                                onClick={closeFileValidateModal}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>

                </div>

                <div className="row1">
                    <div className="element" >
                        <p>
                            <b>Last Updated By : </b>
                            {isNonStateUser ? maskUpdatedBy(recordData?.updated_by) : recordData?.updated_by}
                        </p>
                    </div>
                    <div className="element" >
                        <p><b>Last Updated Date : </b>{formatDateAndTime(recordData?.updated_date)}</p>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
}