import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _, { set } from 'lodash'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import moment from 'moment'
import Modal from 'react-modal';
import { useLooksUpData } from '../../utils/getLookupTableData';
import './user-management.css'
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { FaDownload } from 'react-icons/fa';

export default function UserManagementDetails() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const [actionLKPCDList, setActionLKPCDList] = useState<any>([])
    const [afStatusLKPCDList, setAfStatusLKPCDList] = useState<any>([])
    const [accountStatusLKPCDList, setAccountStatusLKPCDList] = useState<any>([])
    const userType = sessionStorage.getItem('signedInUserType');
    const { getMasterTableLookupValueData, getMasterTableLookupData, getUserGroupTableLookupData, getOrganizationUnitsTableLookupData } = useLooksUpData();
    const ReportInventoryService = useReportInventoryService();
    let [userRoleGroupList, setUserRoleGroupList] = useState<any>([]);
    let [mcUserGroupsList, setMcUserGroupsList] = useState<any>([]);
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);
    let [reporPlanOptions, setReportPlanOptions] = useState<any[]>([]);
    let [userReviewAssignmentList, setUserReviewAssignmentList] = useState<any>([]);
    let [userXUserGroupList, setUserXUserGroupList] = useState<any>([]);
    let [accountTypeList, setAccountTypeList] = useState<any>([]);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcActionLKPCD = filterAndMapData('NG', 'MC_USER', 'ACCOUNT_STATUS_LKPCD');
                const mcAfStatusLKPCD = filterAndMapData('NG', 'MC_USER', 'AF_STATUS_LKPCD');
                const mcAccountStatusLKPCD = filterAndMapData('NG', 'MC_USER', 'ACCOUNT_STATUS_LKPCD');
                const mcAccountTypeLKPCD = filterAndMapData('NG', 'MC_USER', 'ACCOUNT_TYPE_LKPCD');


                setActionLKPCDList(mcActionLKPCD);
                setAfStatusLKPCDList(mcAfStatusLKPCD);
                setAccountStatusLKPCDList(mcAccountStatusLKPCD);
                setAccountTypeList(mcAccountTypeLKPCD)
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcUserGroupTableLookupValueData = async () => {
        try {
            const response = await getUserGroupTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            userGroupCode: `${code.userGroupCode}`,
                            userGroupName: code.userGroupName,
                            userGroupUsage: code.userGroupUsage,
                            oprtnlFlag: code.oprtnlFlag,
                        }));
                };

                setMcUserGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcOrganizationUnitsTableLookupValueData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchUserXUserGroupData = async (userSid: any) => {
        try {
            const response = await ReportInventoryService.getUserXUserGroupList(userSid);
            if (response) {
                setUserXUserGroupList(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchReviewAssignmentData = async (userSid: any) => {
        try {
            const response = await ReportInventoryService.getReviewAssignmentList(userSid);
            if (response) {
                setUserReviewAssignmentList(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchGetMasterTableLookupData = async () => {
        try {
            getMasterTableLookupData().then((response: any) => {
                if (response) {
                    const mcPlanOptions = response.data.mc_plan.map((item: { planId: string, planName: string }) => ({
                        planId: item.planId,
                        planName: item.planName,
                    }));

                    setReportPlanOptions(mcPlanOptions);
                }

            })

        } catch (e) {
            console.log(e);
        }
    }


    interface UserDetailsJson {
        userSid: string;
        organizationUnitCode: string;
        userLastName: string;
        userMiddleName: string;
        userFirstName: string;
        userEmail: string;
        userNamePrefix: string;
        userNameSuffix: string;
        phoneNumber1: string;
        phoneNumber2: string;
        userStreetAddress1: string;
        userStreetAddress2: string;
        userStreetAddress3: string;
        city: string;
        stateLkpcd: string;
        zipCode: string;
        areaCode: string;
        startDate: string;
        endDate: string;
        accountType: string;
        afStatusLkpcd: string;
        accountStatusLkpcd: string;
        updatedDate: string;
        accountTypeLkpcd: string;
    };

    interface LocationState {
        recordData?: UserDetailsJson;
    }

    const recordData = (location?.state as LocationState)?.recordData || null;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await fetchGetMasterTableLookupValueData();
                await fetchGetMcUserGroupTableLookupValueData();
                await fetchGetMcOrganizationUnitsTableLookupValueData();
                if (recordData?.userSid) {
                    await fetchUserXUserGroupData(recordData?.userSid);
                    await fetchReviewAssignmentData(recordData?.userSid);
                }
                await fetchGetMasterTableLookupData();

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    function handleCancel() {
        history.goBack()
    }

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };


    const matchingActionLkpcd = (data: any) => getMatchingDescription(actionLKPCDList, data);
    const matchingAfStatusLkpcd = (data: any) => getMatchingDescription(afStatusLKPCDList, data);
    const matchingAccountStatusLkpcd = (data: any) => getMatchingDescription(accountStatusLKPCDList, data);
    const matchingAccountTypeLkpcd = (data: any) => getMatchingDescription(accountTypeList, data);

    const getMatchingMcUserGroup = (data: any) => {
        const matchedItem = mcUserGroupsList.find((code: any) => code.userGroupCode === data);
        return matchedItem?.userGroupName || data;
    };

    const getMatchingMcOrgUnit = (data: any) => {
        const matchedItem = mcOrgUnitsList.find((code: any) => code.organizationUnitCode === data);
        return matchedItem?.organizationUnitName || data;
    };

    const getMatchingMcPlanId = (data: any) => {
        const matchedItem = reporPlanOptions.find((code: any) => code.planId === data);
        return matchedItem?.planName || data;
    };

    const gridStyle = { minHeight: 340, width: '100%' };

    const formatDateForInput = (value: any) => {
        if (value) {
            const date = moment(value);
            return date.isValid() ? date.format('MM/DD/YYYY') : '';
        }
    };

    const formatDateAndTime = (value: any) => {
        if (value) {
            const date = moment(value);
            return date.isValid() ? date.format('M/D/YY hh:mm:ss:SSS A') : '';
        }
        return '';

    };

    const UserRoleGroupJsonsHeaders: any[] = [
        {
            name: 'userGroupCode',
            header: (
                <div className='grid-header-custom-style'>
                    Role
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{getMatchingMcUserGroup(value)}</div>
            ),
        },
        {
            name: 'fromDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective From Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'toDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective To Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            render: ({ value }: { value: any }) => (
                <div>{formatDateAndTime(value)}</div>
            ),
        },
    ]

    const ReviewsAssignmentsJsonsHeaders: any[] = [
        {
            name: 'organizationUnitCode',
            header: (
                <div className='grid-header-custom-style'>
                    Reviewers Group
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{getMatchingMcOrgUnit(value)}</div>
            ),

        },
        {
            name: 'planId',
            header: (
                <div className='grid-header-custom-style'>
                    Plan
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{getMatchingMcPlanId(value)}</div>
            ),
        },
        {
            name: 'fromDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective From Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'toDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective To Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            render: ({ value }: { value: any }) => (
                <div>{formatDateAndTime(value)}</div>
            ),
        },
    ]

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="User Details" />
                </div>
            </div>
            {isLoading ? (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Loading.....</p>
                </div>
            ) :
                <>
                    <div className="form-layout">
                        <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                            <Button
                                className="OkButton"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </Button>
                        </div>

                        <div className="details-block">
                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Organization Name"
                                        name="organizationUnitCode"
                                        placeholder="Organization Name"
                                        value={getMatchingMcOrgUnit(recordData?.organizationUnitCode)}
                                        disabled={true}

                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="User/Email ID"
                                        name="userEmail"
                                        placeholder="User/Email ID"
                                        value={recordData?.userEmail}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Contact Phone Number"
                                        name="phoneNumber1"
                                        placeholder="Contact Phone Number"
                                        value={recordData?.phoneNumber1}
                                        mask={'+1\\(999)-999 9999'}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="First Name"
                                        name="userFirstName"
                                        placeholder="First Name"
                                        value={recordData?.userFirstName}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Middle Name"
                                        name="userMiddleName"
                                        placeholder="Middle Name"
                                        value={recordData?.userMiddleName}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Last Name"
                                        name="userLastName"
                                        placeholder="Last Name"
                                        value={recordData?.userLastName}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Prefix"
                                        name="userNamePrefix"
                                        placeholder="Prefix"
                                        value={recordData?.userNamePrefix}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Suffix"
                                        name="userNameSuffix"
                                        placeholder="Suffix"
                                        value={recordData?.userNameSuffix}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Alt. Phone Number"
                                        name="phoneNumber2"
                                        placeholder="Alt. Phone Number"
                                        value={recordData?.phoneNumber2}
                                        mask={'+1\\(999)-999 9999'}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Street Address 1"
                                        name="userStreetAddress1"
                                        placeholder="Street Address 1"
                                        value={recordData?.userStreetAddress1}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Street Address 2"
                                        name="userStreetAddress2"
                                        placeholder="Street Address 2"
                                        value={recordData?.userStreetAddress2}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Street Address 3"
                                        name="userStreetAddress3"
                                        placeholder="Street Address 3"
                                        value={recordData?.userStreetAddress3}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="City"
                                        name="city"
                                        placeholder="City"
                                        value={recordData?.city}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="State"
                                        name="stateLkpcd"
                                        placeholder="State"
                                        value={recordData?.stateLkpcd}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Zip"
                                        name="zipCode"
                                        placeholder="Zip"
                                        value={recordData?.zipCode}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Effective From Date"
                                        name="startDate"
                                        placeholder="Effective From Date"
                                        value={formatDateForInput(recordData?.startDate)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Effective To Date"
                                        name="endDate"
                                        placeholder="Effective To Date"
                                        value={formatDateForInput(recordData?.endDate)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Account Type"
                                        name="accountTypeLkpcd"
                                        placeholder="Account Type"
                                        value={matchingAccountTypeLkpcd(recordData?.accountTypeLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Account Status"
                                        name="accountStatusLkpcd"
                                        placeholder="Account Status"
                                        value={matchingAccountStatusLkpcd(recordData?.accountStatusLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label=" Review Status"
                                        name="afStatusLkpcd"
                                        placeholder=" Review Status"
                                        value={matchingAfStatusLkpcd(recordData?.afStatusLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Updated Datetime"
                                        name="updatedDate"
                                        placeholder="Updated Datetime"
                                        value={formatDateAndTime(recordData?.updatedDate)}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="details-block">
                            <h3><b>User Role Groups</b></h3>
                            <ReactDataGrid
                                licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                idProperty="key"
                                style={gridStyle}
                                pagination
                                columns={UserRoleGroupJsonsHeaders}
                                dataSource={userXUserGroupList}
                                sortable={true}
                                // onFilterValueChange={handleFilterChangeGrid}
                                defaultLimit={5}
                                // defaultFilterValue={filterValue}
                                rowHeight={50}
                                // onRowClick={({ data }) => handleRowClick(data)}
                                // className="pointer-cursor-row"
                                enableColumnAutosize={true}
                            />
                        </div>

                        <div className="details-block">
                            <h3><b>Reviews Assignments</b></h3>
                            <ReactDataGrid
                                licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                idProperty="key"
                                style={gridStyle}
                                pagination
                                columns={ReviewsAssignmentsJsonsHeaders}
                                dataSource={userReviewAssignmentList}
                                sortable={true}
                                // onFilterValueChange={handleFilterChangeGrid}
                                defaultLimit={5}
                                // defaultFilterValue={filterValue}
                                rowHeight={50}
                                // onRowClick={({ data }) => handleRowClick(data)}
                                // className="pointer-cursor-row"
                                enableColumnAutosize={true}
                            />
                        </div>
                    </div >
                </>
            }

            <Footer />
        </>
    );
}