import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { set } from 'lodash';
import _, { concat } from 'lodash'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import Modal from 'react-modal';
import * as XLSX from 'xlsx'
import './user-management.css'
import moment from 'moment'
import { useLooksUpData } from '../../utils/getLookupTableData';
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import Cookies from 'js-cookie';

const UserManagement = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const gridStyle = { minHeight: 550, width: '100%' };
    const ReportInventoryService = useReportInventoryService();
    let [mcUsersList, setMcUsersList] = useState<UserList[]>([]);
    const [selectedOption1, setSelectedOption1] = useState<any>(null);
    const [selectedOption2, setSelectedOption2] = useState<any>(null);
    const [filter1Text, setFilter1Text] = useState<string | null>(null);
    const [filter2Text, setFilter2Text] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [gridRef, setGridRef] = useState(null as any)
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);
    const [accountStatusLKPCDList, setAccountStatusLKPCDList] = useState<any>([])
    const { getMasterTableLookupValueData, getOrganizationUnitsTableLookupData, getUserGroupTableLookupData } = useLooksUpData();
    const { checkAccess } = useAccessControl();
    const [filteredReportResponseList, setFilteredReportResponseList] = useState<UserList[]>([]);
    let [accountTypeList, setAccountTypeList] = useState<any>([]);
    let [reviewersGroupsList, setReviewersGroupsList] = useState<any>([]);
    let [mcUserGroupsList, setMcUserGroupsList] = useState<any>([]);
    const loggedInUserDetails = JSON.parse(Cookies.get('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";

    interface UserList {
        userSid: string;
        organizationUnitCode: string;
        userId: string;
        accountTypeLkpcd: string;
    }

    const headers = [
        'Organization',
        'Last Name',
        'First Name',
        'User/Email ID',
        'Effective From Data',
        'Effective To Date',
        'Account Status',
        'Updated Date'
    ];
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);

    const fetchGetMcOrganizationUnitsTableLookupValueData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        .filter((dt: any) => dt.organizationUnitCtgryLkpcd === 'NMR')
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcAccountStatusLKPCD = filterAndMapData('NG', 'MC_USER', 'ACCOUNT_STATUS_LKPCD');
                const mcAccountTypeLKPCD = filterAndMapData('NG', 'MC_USER', 'ACCOUNT_TYPE_LKPCD');

                setAccountStatusLKPCDList(mcAccountStatusLKPCD);
                setAccountTypeList(mcAccountTypeLKPCD)
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchAllMcUsersData = async () => {
        try {
            const response = await ReportInventoryService.getAllMcUsers();
            if (response) {
                setMcUsersList(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getMcUserGroupsByGroupCode = async (groupCode: any) => {
        try {
            const response = await ReportInventoryService.getMcUserGroupsByGroupCode(groupCode);
            if (response) {
                // setFilteredReportResponseList(response.data);
                return response.data;
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getReviewAssignmentListByUnitCode = async (orgUnitCode: any) => {
        try {
            const response = await ReportInventoryService.getReviewAssignmentListByUnitCode(orgUnitCode);
            if (response) {
                // setMcUsersList(response.data);
                return response.data;
            }
        } catch (e) {
            console.error(e);
        }
    };


    const fetchGetMcUserGroupTableLookupValueData = async () => {
        try {
            const response = await getUserGroupTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            userGroupCode: `${code.userGroupCode}`,
                            userGroupName: code.userGroupName,
                            userGroupUsage: code.userGroupUsage,
                            oprtnlFlag: code.oprtnlFlag,
                        }));
                };

                setMcUserGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAllReportReviewer = async () => {
        try {
            const response = await ReportInventoryService.getAllReportReviewer();
            if (response) {
                const { data } = response;
                setReviewersGroupsList(data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await fetchAllMcUsersData();
                await fetchGetMasterTableLookupValueData();
                await fetchGetMcOrganizationUnitsTableLookupValueData();
                await getAllReportReviewer();
                await fetchGetMcUserGroupTableLookupValueData();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if ((!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history]);


    useEffect(() => {
        setFilteredReportResponseList(mcUsersList);
    }, [mcUsersList]);

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingAccountStatusLkpcd = (data: any) => getMatchingDescription(accountStatusLKPCDList, data);

    const serachDropdownChoices1 = [
        {
            label: 'Account Type',
            value: 'accountTypeLkpcd',
        },
        {
            label: 'Reviwers Group',
            value: 'reviwersGroup',
        },
        {
            label: 'User Groups',
            value: 'userGroups',
        }
    ]

    const serachDropdownChoices2 = [
        {
            label: 'Account Type',
            value: 'accountTypeLkpcd',
        },
        {
            label: 'Reviwers Group',
            value: 'reviwersGroup',
        },
        {
            label: 'User Groups',
            value: 'userGroups',
        }
    ]

    const validateSearchBtn = () => {
        if (!(_.isEmpty(selectedOption1) || _.isEmpty(selectedOption2) || _.isEmpty(filter1Text) || _.isEmpty(filter2Text))) {
            return false;
        }
        return true;
    };

    function handleFilterChange1(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        setIsDisabled(validateSearchBtn());
        setFilter1Text('');
    }

    function handleFilterChange2(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        setIsDisabled(validateSearchBtn());
        setFilter2Text('');
    }

    function handleFilterText1(event: any) {
        const enteredValue = event.target.value;
        setFilter1Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText2(event: any) {
        const enteredValue = event.target.value;
        setFilter2Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleClear() {
        setFilter1Text('');
        setFilter2Text('');
        setSelectedOption1(null);
        setSelectedOption2(null);
        setFilteredReportResponseList(mcUsersList);
    }
    async function handleSearch() {
        let filter1Value: string | number | Date | null = filter1Text;
        let filter2Value: string | number | Date | null = filter2Text;
        let programReportSids: string[] = [];
        let userSids: string[] = [];

        // Determine the filter1Value based on selectedOption1
        if (selectedOption1 === 'accountTypeLkpcd') {
            const matchingValue = accountTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter1Text;
        } else if (selectedOption1 === 'reviwersGroup') {
            const matchingValue = mcUserGroupsList.find((code: any) => code.userGroupName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            if (matchingValue) {
                const matchingReviewerGroup = await getMcUserGroupsByGroupCode(matchingValue.userGroupCode);
                if (matchingReviewerGroup) {
                    userSids = matchingReviewerGroup.map((group: any) => group.userSid);
                }
            }
        } else if (selectedOption1 === 'userGroups') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            if (matchingValue) {
                const matchingReviewerGroup = await getReviewAssignmentListByUnitCode(matchingValue.organizationUnitCode);
                if (matchingReviewerGroup) {
                    userSids = matchingReviewerGroup.map((group: any) => group.userSid);
                }
            }
        }

        // Determine the filter2Value based on selectedOption2
        if (selectedOption2 === 'accountTypeLkpcd') {
            const matchingValue = accountTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter2Text;
        } else if (selectedOption2 === 'reviwersGroup') {
            const matchingValue = mcUserGroupsList.find((code: any) => code.userGroupName.toLowerCase().includes((filter2Text || '').toLowerCase()));
            if (matchingValue) {
                const matchingReviewerGroup = await getMcUserGroupsByGroupCode(matchingValue.userGroupCode);
                if (matchingReviewerGroup) {
                    userSids = matchingReviewerGroup.map((group: any) => group.userSid);
                }
            }
        } else if (selectedOption2 === 'userGroups') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            if (matchingValue) {
                const matchingReviewerGroup = await getReviewAssignmentListByUnitCode(matchingValue.organizationUnitCode);
                if (matchingReviewerGroup) {
                    userSids = matchingReviewerGroup.map((group: any) => group.userSid);
                }
            }
        }

        const filteredList = mcUsersList.filter((item: any) => {
            const matchesOption1 = selectedOption1
                ? selectedOption1 === 'reviwersGroup' || selectedOption1 === 'userGroups'
                    ? userSids.includes(item.userSid)
                    : typeof item[selectedOption1 as keyof UserList] === 'string'
                        ? (item[selectedOption1 as keyof UserList] as string).toLowerCase().includes((filter1Value as string).toLowerCase())
                        : item[selectedOption1 as keyof UserList] === filter1Value
                : true;

            const matchesOption2 = selectedOption2
                ? selectedOption2 === 'reviwersGroup' || selectedOption1 === 'userGroups'
                    ? userSids.includes(item.userSid)
                    : typeof item[selectedOption2 as keyof UserList] === 'string'
                        ? (item[selectedOption2 as keyof UserList] as string).toLowerCase().includes((filter2Value as string).toLowerCase())
                        : item[selectedOption2 as keyof UserList] === filter2Value
                : true;

            return matchesOption1 && matchesOption2;
        });


        setFilteredReportResponseList(filteredList);
    }

    const toggleModal = () => {
        setSelectedHeaders(headers);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === headers.length ? [] : headers);
    };

    const handleExportToExcel = () => {
        const dataToExport = mcUsersList.map((item: any) => {
            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    'Organization': item.organizationUnitCode,
                    'Last Name': item.userLastName,
                    'First Name': item.userFirstName,
                    'User/Email ID': item.userEmail,
                    'Effective From Data': item.startDate,
                    'Effective To Date': item.endDate,
                    'Account Status': item.afStatusLkpcd,
                    'Updated Date': item.updatedDate
                }[header]
            });
            return row;
        });

        // Create a worksheet
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'report inventory list');
        XLSX.writeFile(wb, 'report_inventory_list.xlsx');
        setIsExportModalOpen(false);
    };

    const getMatchingMcOrgUnit = (data: any) => {
        const matchedItem = mcOrgUnitsList.find((code: any) => code.organizationUnitCode === data);
        return matchedItem?.organizationUnitName || data;
    };

    const formatDateForInput = (value: any) => {
        if (value) {
            const date = moment(value);
            return date.isValid() ? date.format('MM/DD/YYYY') : '';
        }
    };

    const formatDateAndTime = (value: any) => {
        if (value) {
            const date = moment(value);
            return date.isValid() ? date.format('M/D/YY h:mm:ss:SSS A') : '';
        }
        return '';

    };

    const UserInventoryHeaders: any[] = [
        {
            name: 'organizationUnitCode',
            header: (
                <div className='grid-header-custom-style'>
                    Organization
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            // render: ({ value }: { value: any }) => (
            //     <div>{getMatchingMcOrgUnit(value)}</div>
            // ),
        },
        {
            name: 'userLastName',
            header: (
                <div className='grid-header-custom-style'>
                    Last Name
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
        },
        {
            name: 'userFirstName',
            header: (
                <div className='grid-header-custom-style'>
                    First Name
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
        },
        {
            name: 'userEmail',
            header: (
                <div className='grid-header-custom-style'>
                    User/Email ID
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
        },
        {
            name: 'startDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective From Data
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
        },
        {
            name: 'endDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective To Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
        },
        {
            name: 'afStatusLkpcd',
            header: (
                <div className='grid-header-custom-style'>
                    Account Status
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{matchingAccountStatusLkpcd(value)}</div>
            ),
        },
        {
            name: 'updatedDate',
            header: (
                <div className='grid-header-custom-style'>
                    Updated Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateAndTime(value)}</div>
            ),
        },
    ];

    const handleRowClick = (data: any) => {
        if (!checkAccess('user_inventory_page', 'NOAC')) {
            history.push({
                pathname: '/user-management-details',
                state: { recordData: data }
            });
        }
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Users Inventory" />
                </div>
            </div>
            <div className="form-layout">
                {!isNonStateUser ? (
                    <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                        <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                            <Dropdown
                                choices={serachDropdownChoices1}
                                emptyChoiceLabel="Select Filter Criteria 1"
                                name="filterCriteria1"
                                label=""
                                data-testid="payment_type_testid"
                                value={selectedOption1 ? selectedOption1 : ''}
                                onChange={handleFilterChange1}
                            />
                        </div>
                        <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                            <InputText
                                context="registration"
                                name="search"
                                placeholder="Filter 1"
                                value={filter1Text || ''}
                                onChange={handleFilterText1}
                                small={true}
                            />
                        </div>
                        <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                            <Dropdown
                                choices={serachDropdownChoices2}
                                emptyChoiceLabel="Select Filter Criteria 2"
                                name="filterCriteria2"
                                label=""
                                data-testid="payment_type_testid"
                                value={selectedOption2 ? selectedOption2 : ''}
                                onChange={handleFilterChange2}
                            />
                        </div>
                        <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                            <InputText
                                context="registration"
                                name="search"
                                placeholder="Filter 2"
                                value={filter2Text || ''}
                                onChange={handleFilterText2}
                                small={true}
                            />
                        </div>
                        <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                            <Button
                                disabled={
                                    !(
                                        (selectedOption1 && filter1Text) ||
                                        (selectedOption2 && filter2Text)
                                    )
                                }
                                className="OkButton"
                                onClick={() => handleClear()}
                            >
                                Clear
                            </Button>
                            <Button
                                disabled={
                                    !(
                                        (selectedOption1 && filter1Text) ||
                                        (selectedOption2 && filter2Text)
                                    )
                                }
                                className="OkButton"
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                            <Button
                                className="OkButton"
                                onClick={toggleModal}
                                disabled={(!mcUsersList || mcUsersList.length === 0) || checkAccess('user_inventory_page', ['VWNA', 'NOAC'])}
                            >
                                Export to Excel
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                        <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                            <Button
                                className="OkButton"
                                onClick={toggleModal}
                                disabled={(!mcUsersList || mcUsersList.length === 0) || checkAccess('user_inventory_page', ['VWNA', 'NOAC'])}
                            >
                                Export to Excel
                            </Button>
                        </div>
                    </div>
                )}


                <Modal
                    isOpen={isExportModalOpen}
                    onRequestClose={toggleModal}
                    contentLabel="Export data"
                    style={{
                        content: {
                            width: '500px',
                            height: '500px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <h2 style={{ marginBottom: '20px' }}><b>Select Fields for Export</b></h2>
                    <div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectedHeaders.length === headers.length}
                                onChange={handleSelectAllChange}
                                style={{ width: '15px', height: '15px', marginRight: '10px' }}
                            />
                            <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {headers.map((header) => (
                                <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        id={header}
                                        checked={selectedHeaders.includes(header)}
                                        onChange={() => handleHeaderChange(header)}
                                        style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                    />
                                    <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <button
                            disabled={selectedHeaders.length === 0 ||
                                checkAccess('user_inventory_page', 'VWNA') || checkAccess('user_inventory_page', 'NOAC')
                            }
                            className='usa-button'
                            onClick={handleExportToExcel}
                        >
                            Download Excel
                        </button>
                        <button
                            className='usa-button'
                            onClick={toggleModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading.....</p>
                    </div>
                ) :
                    <>
                        <ReactDataGrid
                            licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                            idProperty="key"
                            style={gridStyle}
                            pagination
                            columns={UserInventoryHeaders}
                            dataSource={filteredReportResponseList}
                            sortable={true}
                            // onFilterValueChange={handleFilterChangeGrid}
                            defaultLimit={10}
                            // defaultFilterValue={filterValue}
                            rowHeight={50}
                            onRowClick={({ data }) => handleRowClick(data)}
                            className="pointer-cursor-row"
                            enableColumnAutosize={true}
                            handle={setGridRef}
                        />
                    </>
                }
            </div>
            <Footer />
        </>

    );
}
export default UserManagement;