import { useReportInventoryService } from '../services/ReportInventoryServices';

let masterLookupValueData = null;
let masterLookupData = null;
let mcUserGroupsData = null;
let mcOrganizationUnitsData = null;
let userGroupsData = null;

const CACHE_DURATION = 60 * 60 * 1000;

const setCacheWithExpiration = (cacheSetter, data) => {
  cacheSetter(data);
  setTimeout(() => {
    cacheSetter(null);
  }, CACHE_DURATION);
};

export const useLooksUpData = () => {
  const ReportInventoryService = useReportInventoryService();

  const fetchData = async (cache, apiCall, cacheSetter) => {
    if (cache !== null) {
      console.log('Using cached data, not calling API again');
      return Promise.resolve(cache);
    } else {
      console.log('Calling API for new data');
      try {
        const response = await apiCall();
        setCacheWithExpiration(cacheSetter, response);
        return response;
      } catch (error) {
        console.error('Error fetching data from API:', error);
        throw error;
      }
    }
  };

  const getMasterTableLookupValueData = () => fetchData(
    masterLookupValueData,
    ReportInventoryService.getMasterLookupValuesDate,
    (data) => { masterLookupValueData = data; }
  );

  const getMasterTableLookupData = () => fetchData(
    masterLookupData,
    ReportInventoryService.getMasterLookupDate,
    (data) => { masterLookupData = data; }
  );

  const getUserGroupTableLookupData = () => fetchData(
    mcUserGroupsData,
    ReportInventoryService.getMcUserGroups,
    (data) => { mcUserGroupsData = data; }
  );

  const getOrganizationUnitsTableLookupData = () => fetchData(
    mcOrganizationUnitsData,
    ReportInventoryService.getMcOrganizationUnits,
    (data) => { mcOrganizationUnitsData = data; }
  );

  const getUserGroupsData = () => fetchData(
    userGroupsData,
    ReportInventoryService.getAllUserGroups,
    (data) => { userGroupsData = data; }
  );

  return {
    getMasterTableLookupValueData,
    getMasterTableLookupData,
    getUserGroupTableLookupData,
    getOrganizationUnitsTableLookupData,
    getUserGroupsData
  };
};