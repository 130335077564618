import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _ from 'lodash'
import './contract-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import './contract-report-home.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import moment from 'moment'
import MultiSelectDropdown from '../../component/Common/CheckboxDropdown'

export default function ContractReportInventoryDetails() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();

    interface ContractReport {
        refNo: string;
        healthPlan: string;
        reportName?: string;
        frequency: string;
        startDate: string;
        endDate: string;
        channel: string;
        submissionDate: string;
        submissinStatus: string;
    };

    interface LocationState {
        recordData?: ContractReport;
        reviewHistoryData?: ContractReviewHistory;
    }

    const { recordData, reviewHistoryData } = (location?.state as LocationState) || null;

    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    interface ContractReviewHistory {
        activityStatus: string;
        date: string;
        status: string;
    };

    const handleRichTextChangeNotes = (event: any, name: any) => {
        // setBusinessOutcome((prevState: any) => ({ ...prevState, [name]: event }))
    }
    function joinClasses(...classes: (string | undefined)[]): string {
        return classes.filter(Boolean).join(' ')
    }
    const wrapperClasses = joinClasses('usa-fieldset usa-form-group')

    function handleGeneralInputData(event: any) {
        const { name, value } = event.target
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const formatDateForInput = (dateString: string) => {
        const [month, day, year] = dateString.split('/');
        return `${year}-${month}-${day}`;
    };

    const reviewStatusDropdown = [
        { label: 'In Review', value: 'In Review' },
        { label: 'Resubmission', value: 'reSubmission' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Closed', value: 'Closed' },
        { label: 'Pending', value: 'Pending' }
    ];

    const formattedStartDate = recordData?.startDate ? formatDateForInput(recordData.startDate) : '';
    const formattedEndDate = recordData?.endDate ? formatDateForInput(recordData.endDate) : '';
    const formattedSubmissionDate = recordData?.submissionDate ? formatDateForInput(recordData.submissionDate) : '';

    const reportReviewChecklist = [
        { label: 'Does it meet established quality standard for the content', value: 'doesItMeetEstablished' },
        { label: 'Is the report complete?', value: 'isReportComplete' },
        { label: 'Cross reference check with the FWA report', value: 'crossRefCheckWithFWA' },
        { label: 'Cross Reference with other HealthPlans', value: 'crossRefCheckWithHealthPlan' },
    ];

    const [selectedValues, setSelectedValues] = useState<number[]>([])
    const handleMultiDropdownChange = (values: any) => {
        const stringValue = values.map(String).join(', ')
        // setUploadNewReport((prevState: any) => ({
        //     ...prevState, ['reportUploadChecklist']: stringValue,
        // }))
        setSelectedValues(values)
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Contract Report - Program Review" />
                </div>
            </div>

            <div className="form-layout">
                <div className="details-block">
                    <h3><b>General Information:</b></h3>
                    <div className="line">
                        <InputText
                            context="registration"
                            label="Reference Number"
                            name="refNo"
                            placeholder="Reference Number"
                            value={recordData?.refNo}
                            onChange={handleGeneralInputData}
                            mandatoryLabel={true}
                        />
                        <InputText
                            context="registration"
                            label="Report Name"
                            name="reportName"
                            placeholder="Report Name"
                            value={recordData?.reportName}
                            onChange={handleGeneralInputData}
                            mandatoryLabel={true}
                        />
                        <InputText
                            context="registration"
                            label="Health Plan"
                            name="healthPlan"
                            placeholder="Health Plan"
                            value={recordData?.healthPlan}
                            onChange={handleGeneralInputData}
                            mandatoryLabel={true}
                        />
                    </div>
                    <div className="line">
                        <InputText
                            context="registration"
                            label="Channel"
                            name="channel"
                            placeholder="Channel"
                            value={recordData?.channel}
                            onChange={handleGeneralInputData}
                            mandatoryLabel={true}
                        />
                        <InputText
                            context="registration"
                            label="Frequency"
                            name="frequency"
                            placeholder="Frequency"
                            value={recordData?.frequency}
                            onChange={handleGeneralInputData}
                            mandatoryLabel={true}
                        />

                        <InputText
                            context="registration"
                            label="Submission Status"
                            name="submissinStatus"
                            placeholder="Submission Status"
                            value={recordData?.submissinStatus}
                            onChange={handleGeneralInputData}
                            mandatoryLabel={true}
                        />
                    </div>
                    <div className="line">
                        <div className="usa-form-group">
                            <FormInput
                                label="Start Date"
                                type="date"
                                name="startDate"
                                value={formattedStartDate}
                                onChange={handleGeneralInputData}
                                placeholder={'MM-DD-YYYY'}
                                id={''}
                                required={true}
                            />
                        </div>
                        <div className="usa-form-group">
                            <FormInput
                                label="End Date"
                                type="date"
                                name="endDate"
                                value={formattedEndDate}
                                onChange={handleGeneralInputData}
                                placeholder={'MM-DD-YYYY'}
                                id={''}
                                required={true}
                            />
                        </div>
                        <div className="usa-form-group">
                            <FormInput
                                label="Submission Date"
                                type="date"
                                name="submissionDate"
                                value={formattedSubmissionDate}
                                onChange={handleGeneralInputData}
                                placeholder={'MM-DD-YYYY'}
                                id={''}
                                required={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="details-block">
                    <h3><b>Program Review Details:</b></h3>
                    <div className="line">
                        <Dropdown
                            choices={reviewStatusDropdown}
                            emptyChoiceLabel="Select Status"
                            name="activityStatus"
                            label="Status"
                            data-testid="status"
                            value={reviewHistoryData?.activityStatus}
                            onChange={handleGeneralInputData}
                            mandatoryLabel={true}
                        />

                        <div className="usa-form-group" >
                            <MultiSelectDropdown
                                options={reportReviewChecklist}
                                label="Report Upload Checklist"
                                name="reportUploadChecklist"
                                onChange={handleMultiDropdownChange}
                                selectedValues={selectedValues}
                                mandatoryLabel={true}
                            />
                        </div>
                    </div>

                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label>
                            <b>Notes:</b>
                        </label>
                        <ReactQuill
                            style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                            value={"testing"}
                            onChange={(ev) =>
                                handleRichTextChangeNotes(ev, 'notes')
                            }
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'], // formatting options
                                    [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                    [{ align: [] }], // alignment options
                                    ['clean'], // remove formatting option
                                ],
                            }}
                        />
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
}