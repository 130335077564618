import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _, { set } from 'lodash'
import './sftp-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import moment from 'moment'
import Modal from 'react-modal';
import { FaExternalLinkAlt, FaDownload } from 'react-icons/fa';
import Cookies from 'js-cookie';

export default function SftpReportDetails() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const loggedInUserDetails = JSON.parse(Cookies.get('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";
    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    interface SFTPReport {
        sftpUploadDatetime: string;
        fileName: string;
        mcoName: string;
        associatedReportName: string;
        frequency: string;
        period: string;
        dueDate: string;
        currentSubmission: string;
        submissinStatus: string;
        lastUpdatedDate: string;
        lastUpdatedBy: string;
        fileType: string;
        uploadFiles?: string;
    };

    interface LocationState {
        recordData?: SFTPReport;
    }

    const recordData = (location?.state as LocationState)?.recordData || null;

    function handleCancel() {
        history.goBack()
    }

    function handleSaveButton() {
    }



    const fileTypeDropdown = [
        { label: 'Main Report', value: 'Main Report' },
        { label: 'Associated File', value: 'Associated File' },
        { label: 'Additional Info', value: 'Additional Info' },
        { label: 'Certification Statement', value: 'Certification Statement' },
    ];

    const mcoNameDropdown = [
        { label: 'United Health Care', value: 'United Health Care' },
        { label: 'Nebraska Total Care', value: 'Nebraska Total Care' },
        { label: 'Molina', value: 'Molina' },
    ];

    const associationReportDropdown = [
        { label: 'EVV KPI-Home Health', value: 'EVV KPI-Home Health' },
        { label: 'Care Management Report', value: 'Care Management Report' },
        { label: 'Appeals Log', value: 'Appeals Log' },
        { label: 'Bi-Weekly Tips Report', value: 'Bi-Weekly Tips Report' },
    ];

    const reportPeriodDropdown = [
        { label: '06/01/24-06/30/24', value: '06/01/24-06/30/24' },
        { label: '07/01/24-07/31/24', value: '07/01/24-07/31/24' },
        { label: '07/16/24-07/31/24', value: '07/16/24-07/31/24' },
    ];
    const handleRichTextChangeNotes = (event: any, name: any) => {
        // setBusinessOutcome((prevState: any) => ({ ...prevState, [name]: event }))
    }
    function joinClasses(...classes: (string | undefined)[]): string {
        return classes.filter(Boolean).join(' ')
    }
    const wrapperClasses = joinClasses('usa-fieldset usa-form-group')
    const [mcoName, setMcoName] = useState('');
    const [associatedReportName, setAssociatedReportName] = useState('');
    const [reportPeriod, setReportPerios] = useState('');
    function handleGeneralInputData(event: any) {
        const { name, value } = event.target
        setMcoName(value)
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }
    function handleAssociatedData(event: any) {
        const { name, value } = event.target
        setAssociatedReportName(value)
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }
    function handleReportPeriodData(event: any) {
        const { name, value } = event.target
        setReportPerios(value)
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }
    useEffect(() => {
        if (recordData) {
            setMcoName(recordData.mcoName);
            setAssociatedReportName(recordData.associatedReportName);
            setReportPerios(recordData.period);
        }
    }
        , [recordData]);

    const formatDateForInput = (dateString: string) => {
        let parts;
        if (dateString.includes('/')) {
            parts = dateString.split('/');
            return `${parts[2]}-${parts[0]}-${parts[1]}`;
        } else if (dateString.includes('-')) {
            parts = dateString.split('-');
            return `${parts[0]}-${parts[1]}-${parts[2]}`;
        }
        return '';
    }

    type UploadFile = {
        id: string;
        name: string;
        type: string;
    };

    const formattedDueDate = recordData?.dueDate ? formatDateForInput(recordData.dueDate) : '';
    const [fileValue, setFileValue] = useState<UploadFile>({ id: '', name: '', type: '' });

    useEffect(() => {
        if (recordData && typeof recordData.uploadFiles === 'object') {
            const uploadedFiles: UploadFile = recordData.uploadFiles ? recordData.uploadFiles : { id: '', name: '', type: '' };
            setFileValue(uploadedFiles);
        }
    }, [recordData]);


    const handleRichTextChangeAppDetails = (event: any) => {
        const appDetails = event
    }

    const initialCheckboxState = true;

    const [isChecked, setIsChecked] = useState(initialCheckboxState);

    useEffect(() => {
        setIsChecked(initialCheckboxState);
    }, [initialCheckboxState]);

    useEffect(() => {
        if (recordData?.period && recordData?.frequency && recordData?.associatedReportName && recordData?.mcoName && recordData?.fileName &&
            recordData?.currentSubmission && recordData?.submissinStatus && recordData?.dueDate && recordData?.sftpUploadDatetime
        ) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }, [recordData]);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setIsChecked(checked);

        if (!checked) {
            if (isNonStateUser) {
                setMcoName('Molina');
                setAssociatedReportName('');
                setReportPerios('');
            }
            else {
                setMcoName('');
                setAssociatedReportName('');
                setReportPerios('');
            }
        }
    };

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="SFTP Files" />
                </div>
            </div>

            <div className="form-layout">
                <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                    <Button
                        className="OkButton"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="OkButton"
                        onClick={() => handleSaveButton()}
                    >
                        Save
                    </Button>
                </div>
                <div className="details-block">
                    <h3><b>Filter Criteria:</b></h3>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="File Name"
                                name="fileName"
                                placeholder="File Name"
                                value={recordData?.fileName}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="SFTP Upload Datetime"
                                name="sftpUploadDatetime"
                                placeholder="SFTP Upload Datetime"
                                value={recordData?.sftpUploadDatetime}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <Dropdown
                                choices={mcoNameDropdown}
                                emptyChoiceLabel="Select MCO Name"
                                name="mcoName"
                                label="MCO Name "
                                data-testid="payment_type_testid"
                                value={mcoName}
                                onChange={handleGeneralInputData}
                                disabled={!isChecked && isNonStateUser}
                            />
                        </div>
                        <div className="element" >
                            <Dropdown
                                choices={associationReportDropdown}
                                emptyChoiceLabel="Select Associated Report Name"
                                name="associatedReportName"
                                label="Associated Report Name"
                                data-testid="payment_type_testid"
                                value={associatedReportName}
                                onChange={handleAssociatedData}
                            />

                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <Dropdown
                                choices={reportPeriodDropdown}
                                emptyChoiceLabel="Select Report Period"
                                name="period"
                                label="Report Period"
                                data-testid="payment_type_testid"
                                value={reportPeriod}
                                onChange={handleReportPeriodData}
                            />
                        </div>
                        <div className="element" >
                            <div className="usa-form-group ">
                                <input
                                    type="checkbox"
                                    id="isSelectedSftpCheckbox"
                                    style={{ margin: '30px', transform: 'scale(1.5)' }}
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}

                                // onChange={(e) => setIsChecked(e.target.checked)}

                                />
                                <label htmlFor="isSelectedSftpCheckbox">
                                    Link SFTP File
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <div className="usa-form-group">
                                <InputText
                                    context="registration"
                                    label="Frequency"
                                    name="frequency"
                                    placeholder="Frequency"
                                    value={recordData?.frequency}
                                    onChange={handleGeneralInputData}
                                    disabled={true}
                                />

                            </div>
                        </div>
                        <div className="element" >
                            <div className="usa-form-group">
                                <FormInput
                                    label="Due Date"
                                    type="date"
                                    name="dueDate"
                                    value={formattedDueDate}
                                    onChange={handleGeneralInputData}
                                    placeholder={'MM-DD-YYYY'}
                                    id={''}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row1" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', marginTop: '20px' }}>
                        <label >
                            <b>Notes:</b>
                        </label>
                        <ReactQuill
                            style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                            value={''}
                            onChange={handleRichTextChangeAppDetails}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'], // formatting options
                                    [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                    [{ align: [] }], // alignment options
                                    ['clean'], // remove formatting option
                                ],
                            }}
                        />
                    </div>
                    <div className='row1'>
                        <div className="element" >
                            <Dropdown
                                choices={fileTypeDropdown}
                                emptyChoiceLabel="Select File Type"
                                name="fileType"
                                label="File Type"
                                data-testid="payment_type_testid"
                                value={fileValue.type}
                                onChange={handleGeneralInputData}
                            />
                        </div>
                    </div>
                </div>

                <div className="row1">
                    <div className="element" >
                        <p><b>Last Updated By : </b>{recordData?.lastUpdatedBy}</p>
                    </div>
                    <div className="element" >
                        <p><b>Last Updated Date : </b>{recordData?.lastUpdatedDate}</p>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
}