import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './reference-home.css'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import _, { set } from 'lodash'
import * as XLSX from 'xlsx'
import Modal from 'react-modal';
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import moment from 'moment'
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import Cookies from 'js-cookie';

const Reference = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const ReportInventoryService = useReportInventoryService();
    const { getMasterTableLookupValueData, getOrganizationUnitsTableLookupData, getUserGroupTableLookupData } = useLooksUpData();
    const [frequencyLKPCDList, setFrequencyLKPCDList] = useState<any>([])
    const [channelLKPCDList, setChannelLKPCDList] = useState<any>([])
    const { checkAccess } = useAccessControl();
    const [filteredReportResponseList, setFilteredReportResponseList] = useState<ReferenceList[]>([]);
    const gridStyle = { minHeight: 550, width: '100%' };
    const [gridRef, setGridRef] = useState(null as any)
    let [filterData, setFilterData] = useState<ReferenceList[]>([]);
    const [selectedOption1, setSelectedOption1] = useState<any>(null);
    const [selectedOption2, setSelectedOption2] = useState<any>(null);
    const [filter1Text, setFilter1Text] = useState<string | null>(null);
    const [filter2Text, setFilter2Text] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [contentTypeList, setContentTypeList] = useState<any>([])
    const [reportGroupList, setReportGroupList] = useState<any>([])
    const [reportFileFormat, setReportFileFormat] = useState<any>([])
    const [businessStatusLKPCDList, setBusinessStatusLKPCDList] = useState<any>([])
    const [afStatusLKPCDList, setAfStatusLKPCDList] = useState<any>([])
    const [uploadTypeLKPCDList, setUploadTypeLKPCDList] = useState<any>([])
    const [checklistAfStatusLKPCDList, setChecklistAfStatusLKPCDList] = useState<any>([])
    const [mcoActionLKPCDList, setNcoActionLKPCDList] = useState<any>([])
    const [reviewerGroupLKPCDList, setReviewerGroupLKPCDList] = useState<any>([])
    let [reviewersGroupsList, setReviewersGroupsList] = useState<any>([]);
    let [mcUserGroupsList, setMcUserGroupsList] = useState<any>([]);
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);
    const loggedInUserDetails = JSON.parse(Cookies.get('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";

    const serachDropdownChoices1 = [
        {
            label: 'Program',
            value: 'programName',
        },
        {
            label: 'Report Content Type',
            value: 'reportingContentTypeLkpcd',
        },
        {
            label: 'Initial Verification Flag',
            value: 'autoValidationFlag',
        },
        {
            label: 'Need Certification Flag',
            value: 'certificationReqdFlag',
        },
        {
            label: 'Report Begin Date',
            value: 'startDate',
        },
        {
            label: 'Report End Date',
            value: 'endDate',
        },
        {
            label: 'Reviewers Group',
            value: 'reviewersGroup',
        }
    ]

    const serachDropdownChoices2 = [
        {
            label: 'Program',
            value: 'programName',
        },
        {
            label: 'Report Content Type',
            value: 'reportingContentTypeLkpcd',
        },
        {
            label: 'Initial Verification Flag',
            value: 'autoValidationFlag',
        },
        {
            label: 'Need Certification Flag',
            value: 'certificationReqdFlag',
        },
        {
            label: 'Report Begin Date',
            value: 'startDate',
        },
        {
            label: 'Report End Date',
            value: 'endDate',
        },
        {
            label: 'Reviewers Group',
            value: 'reviewersGroup',
        }
    ]

    const userType = sessionStorage.getItem('signedInUserType');

    const fetchAllProgramReportData = async () => {
        setIsLoading(true);
        try {
            const response = await ReportInventoryService.getMcProgramReport();
            if (response) {
                setFilterData(response.data);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const getAllReportReviewer = async () => {
        try {
            const response = await ReportInventoryService.getAllReportReviewer();
            if (response) {
                const { data } = response;
                setReviewersGroupsList(data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcReportType = filterAndMapData('AL', 'MC_PROGRAM_REPORT', 'REPORTING_TYPE_LKPCD');
                const mcContentType = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORTING_CONTENT_TYPE_LKPCD');
                const mcReportingGroup = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORTING_GROUP_QLFR_LKPCD');
                const mcFrequencyLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'DUE_DATE_FREQUENCY_LKPCD');
                const mcSubChannelLKPCD = filterAndMapData('AD', 'MC_PROGRAM_REPORT', 'REPORT_SBMSN_MODE_LKPCD');
                const mcFileFormatLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORT_FILE_TYPE_LKPCD');
                const mcBusinessStatusLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'BUSINESS_STATUS_LKPCD');
                const mcAfStatusLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'AF_STATUS_LKPCD');
                const mcFileUploadType = filterAndMapData('MC', 'MC_REPORT_FILENAME_TEMPLATE', 'UPLOAD_TYPE_LKPCD');
                const mcChecklistAfStatusLkpcde = filterAndMapData('RM', 'MC_CHECKLIST', 'CHECKLIST_TYPE_LKPCD');
                const mcMcoActionLkpcde = filterAndMapData('MC', 'MC_REPORT_REVIEWERS_GROUP', 'ACTION_LKPCD');
                const mcReviewerGroupLkpcde = filterAndMapData('NG', 'MC_REPORT_REVIEWERS_GROUP', 'AF_STATUS_LKPCD');


                setReportTypeList(mcReportType);
                setContentTypeList(mcContentType);
                setReportGroupList(mcReportingGroup);
                setFrequencyLKPCDList(mcFrequencyLKPCD);
                setReportFileFormat(mcFileFormatLKPCD);
                setBusinessStatusLKPCDList(mcBusinessStatusLKPCD);
                setAfStatusLKPCDList(mcAfStatusLKPCD);
                setUploadTypeLKPCDList(mcFileUploadType);
                setChecklistAfStatusLKPCDList(mcChecklistAfStatusLkpcde)
                setNcoActionLKPCDList(mcMcoActionLkpcde)
                setReviewerGroupLKPCDList(mcReviewerGroupLkpcde)
                setChannelLKPCDList(mcSubChannelLKPCD);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcUserGroupTableLookupValueData = async () => {
        try {
            const response = await getUserGroupTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            userGroupCode: `${code.userGroupCode}`,
                            userGroupName: code.userGroupName,
                            userGroupUsage: code.userGroupUsage,
                            oprtnlFlag: code.oprtnlFlag,
                        }));
                };

                setMcUserGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcOrganizationUnitsTableLookupValueData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        .filter((dt: any) => dt.organizationUnitCtgryLkpcd === 'NMR')
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingFrequencyLkpcd = (data: any) => getMatchingDescription(frequencyLKPCDList, data);
    const matchingReportingTypeLkpcd = (data: any) => getMatchingDescription(reportTypeList, data);
    const matchingFormatLkpcd = (data: any) => getMatchingDescription(reportFileFormat, data);
    const matchingChannelLkpcd = (data: any) => getMatchingDescription(channelLKPCDList, data);
    const matchingBusinessStatusLkpcd = (data: any) => getMatchingDescription(businessStatusLKPCDList, data);

    const formatDateAndTime = (value: any) => {
        if (value) {
            const date = moment(value);
            return date.isValid() ? date.format('M/D/YY h:mm A') : '';
        }
        return '';

    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await setIsLoading(true);
                await fetchAllProgramReportData();
                await fetchGetMasterTableLookupValueData()
                await fetchGetMcUserGroupTableLookupValueData()
                await fetchGetMcOrganizationUnitsTableLookupValueData()
                await getAllReportReviewer();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    useEffect(() => {
        setFilteredReportResponseList(filterData);
    }, [filterData]);

    interface ReferenceList {
        reportName: string;
        reportingTypeLkpcd: string;
        stateReferenceNumber: string;
        reportingPeriodQlfrLkpcd: string;
        reportFileTypeLkpcd: string;
        reportSbmsnModeLkpcd: string;
        updatedDate: string;
        businessStatusLkpcd: string;
        programReportSid?: string;
    }

    // Example initialData for useState
    // const initialData: ReferenceList[] = [
    //     { reportName: 'Past Due Reminder1', reportType: "Report Due Not Submitted as per schedule-1", reportingPeriodQlfrLkpcd: "Pharmacy Claims Report-1" },
    //     { reportName: 'Past Due Reminder2', reportType: "Report Due Not Submitted as per schedule-2", reportingPeriodQlfrLkpcd: "Pharmacy Claims Report-2" },
    //     { reportName: 'Past Due Reminder3', reportType: "Report Due Not Submitted as per schedule-3", reportingPeriodQlfrLkpcd: "Pharmacy Claims Report-3" },
    // ];

    const validateSearchBtn = () => {
        if (!(_.isEmpty(selectedOption1) || _.isEmpty(selectedOption2) || _.isEmpty(filter1Text) || _.isEmpty(filter2Text))) {
            return false;
        }
        return true;
    };

    const CustomCellRenderer = ({ value }: { value: string }) => (
        <div style={{ width: 'max-content', whiteSpace: 'nowrap' }}>{value}</div>
    );
    const referenceListHeaders: any[] = [
        {
            name: 'reportName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
        },
        {
            name: 'reportingTypeLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Type
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingReportingTypeLkpcd(data.reportingTypeLkpcd);
            },
        },
        {
            name: 'stateReferenceNumber',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Reference
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
        },
        {
            name: 'reportingPeriodQlfrLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Frequency
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingFrequencyLkpcd(data.reportingPeriodQlfrLkpcd);
            },
        },
        {
            name: 'reportFileTypeLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Format
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingFormatLkpcd(data.reportFileTypeLkpcd);
            },
        },
        {
            name: 'reportSbmsnModeLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission Channel
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingChannelLkpcd(data.reportSbmsnModeLkpcd);
            },
        },
        {
            name: 'businessStatusLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Business Status
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingBusinessStatusLkpcd(data.businessStatusLkpcd);
            },
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return formatDateAndTime(data.updatedDate);
            },
        }

    ]

    function handleFilterChange1(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        setFilter1Text('');
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterChange2(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        setFilter2Text('');
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText1(event: any) {
        const enteredValue = event.target.value;
        setFilter1Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText2(event: any) {
        const enteredValue = event.target.value;
        setFilter2Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleClear() {
        setFilter1Text('');
        setFilter2Text('');
        setSelectedOption1(null);
        setSelectedOption2(null);
        setFilteredReportResponseList(filterData);
    }

    const [viewableText, setViewableText] = useState<any[]>([
        { value: 'Y', description: 'Yes' },
        { value: 'N', description: 'No' },
    ]);
    const getMatchingYesOrNoValues = (data: string): string => {
        const matchedItem = viewableText.find((code) => code.value.toLowerCase() === data.toLowerCase());
        return matchedItem?.description || '';
    };

    const filteredSerachDropdownChoices1 = isNonStateUser
        ? serachDropdownChoices1.filter(option => option.value === 'programName' ||
            option.value === 'autoValidationFlag' || option.value === 'certificationReqdFlag' || option.value === 'startDate' || option.value === 'endDate') : serachDropdownChoices1;

    const filteredSerachDropdownChoices2 = isNonStateUser
        ? serachDropdownChoices2.filter(option => option.value === 'programName' ||
            option.value === 'autoValidationFlag' || option.value === 'certificationReqdFlag' || option.value === 'startDate' || option.value === 'endDate') : serachDropdownChoices2

    function handleSearch() {
        let filter1Value: string | number | Date | null = filter1Text;
        let filter2Value: string | number | Date | null = filter2Text;
        let programReportSids: string[] = [];

        const parseDate = (dateString: string) => {
            const formats = ['MM/DD/YYYY', 'YYYY-MM-DD', 'DD/MM/YYYY', 'MM-DD-YYYY', 'DD-MM-YYYY'];
            const date = moment(dateString, formats, true);
            return date.isValid() ? date.format('YYYY-MM-DD') : null;
        };
        
        // Determine the filter1Value based on selectedOption1
        if (selectedOption1 === 'programName') {
            filter1Value = filter1Text;
        } else if (selectedOption1 === 'reportingContentTypeLkpcd') {
            const matchingValue = contentTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter1Text;
        } else if (selectedOption1 === 'autoValidationFlag') {
            const matchingValue = viewableText.find((code: any) => code.description.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.value : filter1Text;
        } else if (selectedOption1 === 'certificationReqdFlag') {
            const matchingValue = viewableText.find((code: any) => code.description.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.value : filter1Text;
        } else if (selectedOption1 === 'startDate') {
            filter1Value = filter1Text ? parseDate(filter1Text) : '';
        } else if (selectedOption1 === 'endDate') {
            filter1Value = filter1Text ? parseDate(filter1Text) : '';
        } else if (selectedOption1 === 'reviewersGroup') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            const matchingReviewerGroup = reviewersGroupsList.filter((code: any) => code.organizationUnitCode === matchingValue.organizationUnitCode);
            programReportSids = matchingReviewerGroup.map((group: any) => group.programReportSid);
        }

        // Determine the filter2Value based on selectedOption2
        if (selectedOption2 === 'programName') {
            filter2Value = filter2Text;
        } else if (selectedOption2 === 'reportingContentTypeLkpcd') {
            const matchingValue = contentTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter2Text;
        } else if (selectedOption2 === 'autoValidationFlag') {
            const matchingValue = viewableText.find((code: any) => code.description.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.value : filter2Text;
        } else if (selectedOption2 === 'certificationReqdFlag') {
            const matchingValue = viewableText.find((code: any) => code.description.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.value : filter2Text;
        } else if (selectedOption2 === 'startDate') {
            filter2Value = filter2Text ? parseDate(filter2Text) : '';
        } else if (selectedOption2 === 'endDate') {
            filter2Value = filter2Text ? parseDate(filter2Text) : '';
        } else if (selectedOption2 === 'reviewersGroup') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter2Text || '').toLowerCase()));
            const matchingReviewerGroup = reviewersGroupsList.filter((code: any) => code.organizationUnitCode === matchingValue.organizationUnitCode);
            programReportSids = matchingReviewerGroup.map((group: any) => group.programReportSid);
        }

        const filteredList = filterData.filter(item => {
            const matchesOption1 = selectedOption1
                ? selectedOption1 === 'reviewersGroup'
                    ? programReportSids.includes(item.programReportSid || '')
                    : selectedOption1 === 'startDate' || selectedOption1 === 'endDate'
                        ? parseDate(item[selectedOption1 as keyof ReferenceList] as string) === filter1Value
                        : typeof item[selectedOption1 as keyof ReferenceList] === 'string'
                            ? (item[selectedOption1 as keyof ReferenceList] as string).toLowerCase().includes((filter1Value as string).toLowerCase())
                            : item[selectedOption1 as keyof ReferenceList] === filter1Value
                : true;

            const matchesOption2 = selectedOption2
                ? selectedOption2 === 'reviewersGroup'
                    ? programReportSids.includes(item.programReportSid || '')
                    : selectedOption2 === 'startDate' || selectedOption2 === 'endDate'
                        ? parseDate(item[selectedOption2 as keyof ReferenceList] as string) === filter2Value
                        : typeof item[selectedOption2 as keyof ReferenceList] === 'string'
                            ? (item[selectedOption2 as keyof ReferenceList] as string).toLowerCase().includes((filter2Value as string).toLowerCase())
                            : item[selectedOption2 as keyof ReferenceList] === filter2Value
                : true;

            return matchesOption1 && matchesOption2;
        });

        setFilteredReportResponseList(filteredList);
    }

    const headers = [
        'Report Name',
        'Report Type',
        'Reference',
        'Frequency',
        'Format',
        'Submission Channel',
        'Updated Date',
    ];
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);
    const toggleModal = () => {
        setSelectedHeaders(headers);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === headers.length ? [] : headers);
    };

    const handleExportToExcel = () => {
        const dataToExport = filterData.map((item) => {
            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    "Report Name": item.reportName,
                    "Report Type": matchingReportingTypeLkpcd(item.reportingTypeLkpcd),
                    "Reference": item.stateReferenceNumber,
                    "Frequency": matchingFrequencyLkpcd(item.reportingPeriodQlfrLkpcd),
                    "Format": matchingFormatLkpcd(item.reportFileTypeLkpcd),
                    "Submission Channel": matchingChannelLkpcd(item.reportSbmsnModeLkpcd),
                    "Updated Date": item.updatedDate,
                }[header];
            });
            return row;
        });
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Report Master Catalog');
        XLSX.writeFile(wb, 'report_master_catalog.xlsx');
        setIsExportModalOpen(false);
    }

    const handleRowClick = (data: any) => {
        history.push({
            pathname: '/report-catalog-details',
            state: { recordData: data }
        });
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Report Master Catalog" />
                </div>
            </div>

            <div className="form-layout">
                <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices1}
                            emptyChoiceLabel="Select Filter Criteria 1"
                            name="filterCriteria1"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption1 ? selectedOption1 : ''}
                            onChange={handleFilterChange1}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 1"
                            value={filter1Text || ''}
                            onChange={handleFilterText1}
                            small={true}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices2}
                            emptyChoiceLabel="Select Filter Criteria 2"
                            name="filterCriteria2"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption2 ? selectedOption2 : ''}
                            onChange={handleFilterChange2}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 2"
                            value={filter2Text || ''}
                            onChange={handleFilterText2}
                            small={true}
                        />
                    </div>
                    <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={() => handleClear()}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        <Button
                            className="OkButton"
                            onClick={toggleModal}
                            disabled={!filterData || filterData.length === 0 ||
                                checkAccess('report_master_detail_page', 'VWNA') ||
                                checkAccess('report_master_detail_page', 'NOAC')
                            }
                        >
                            Export to Excel
                        </Button>
                    </div>
                </div>

                <Modal
                    isOpen={isExportModalOpen}
                    onRequestClose={toggleModal}
                    contentLabel="Export data"
                    style={{
                        content: {
                            width: '500px',
                            height: '500px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <h2 style={{ marginBottom: '20px' }}><b>Select Fields for Export</b></h2>
                    <div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectedHeaders.length === headers.length}
                                onChange={handleSelectAllChange}
                                style={{ width: '15px', height: '15px', marginRight: '10px' }}
                            />
                            <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {headers.map((header) => (
                                <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        id={header}
                                        checked={selectedHeaders.includes(header)}
                                        onChange={() => handleHeaderChange(header)}
                                        style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                    />
                                    <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <button
                            disabled={selectedHeaders.length === 0}
                            className='usa-button'
                            onClick={handleExportToExcel}
                        >
                            Download Excel
                        </button>
                        <button
                            className='usa-button'
                            onClick={toggleModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading.....</p>
                    </div>
                ) :
                    <>
                        <ReactDataGrid
                            licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                            idProperty="key"
                            style={gridStyle}
                            pagination
                            columns={referenceListHeaders}
                            dataSource={filteredReportResponseList}
                            sortable={true}
                            // onFilterValueChange={handleFilterChangeGrid}
                            defaultLimit={10}
                            // defaultFilterValue={filterValue}
                            // enableColumnAutosize
                            rowHeight={50}
                            onRowClick={({ data }) => handleRowClick(data)}
                            className="pointer-cursor-row"
                            enableColumnAutosize={true}
                            handle={setGridRef}
                        />
                    </>
                }
            </div>
            <Footer />
        </>
    );
}
export default Reference;