export enum SubmissionStatus {
    NOT_SCHEDULED = '05',
    SCHEDULED = '10',
    PAST_DUE = '15',
    EXTENSION_REQUESTED = '20',
    RESCHEDULED = '25',
    SUBMITTED = '30',
    RESUBMITTED = '35',
    REOPEN_REQUESTED = '40',
    CLOSED = '50'
}

export enum ReviewStatus {
    VIRUS_SCAN = '05',
    IN_INITIAL_VALIDATION = '10',
    INITIAL_VALIDATION_FAILED = '15',
    IN_REVIEW = '20',
    ADDITIONAL_INFO_REQUESTED = '30',
    ADDITIONAL_INFO_REQUESTED_VIRUS_SCAN = '32',
    ADDITIONAL_INFO_SUBMITTED = '31',
    ADDITIONAL_INFO_INREVIEW = '35',
    REJECTED = '40',
    ACCEPTED = '50',
}

export enum ChooseActionsType {
    EXTEND = 'ED',
    REOPEN = 'RO',
    SUBMIT = 'SB'
}

export enum ActivityLkpcd {
    ACTIVITY_140 = '140',
    ACTIVITY_150 = '150',
    ACTIVITY_153 = '153',
    ACTIVITY_160 = '160',
    ACTIVITY_180 = '180',
    ACTIVITY_240 = '240',
    ACTIVITY_243 = '243',
    ACTIVITY_250 = '250',
    ACTIVITY_270 = '270',
    ACTIVITY_273 = '273',
    ACTIVITY_280 = '280',
    ACTIVITY_301 = '301',
    ACTIVITY_303 = '303',
    ACTIVITY_305 = '305',
    ACTIVITY_308 = '308',
    ACTIVITY_310 = '310',
    ACTIVITY_313 = '313',
    ACTIVITY_320 = '320',
    ACTIVITY_341 = '341',
    ACTIVITY_343 = '343',
    ACTIVITY_345 = '345',
    ACTIVITY_348 = '348',
    ACTIVITY_350 = '350'
}

export enum AdhocChecklistId {
    CHECKLIST_ID_21 = '21',
    CHECKLIST_ID_22 = '22',
    CHECKLIST_ID_23 = '23'
}

export enum Organization_Unit_CtgryLkpcd {
    NMA = 'NMA',
    NMR = 'NMR',
    NMF = 'NMF',
    MCP = 'MCP',
    NSO = 'NSO',
    NSU = 'NSU'
}

export enum Reviewer_Type {
    IR = 'IR',
    FR = 'FR'
}

export enum CheckList_Type_Lkpcd {
    SFRL = 'SFRL',
    SIRL = 'SIRL',
    MSBL = 'MSBL',
    MAIL = 'MAIL',
    MORL = 'MORL',
    SIRA = 'SIRA',
    MSBA = 'MSBA',
    SFRA = 'SFRA',
}

export enum Final_Reviewer_File_Type_Filter {
    REQUEST_ADD_INFO = 'RI',
    OTHER = 'OT',
}