import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './reminders-home.css'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { useLooksUpData } from '../../utils/getLookupTableData';
import moment from 'moment'
const Reminders = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const ReportInventoryService = useReportInventoryService();
    const userType = sessionStorage.getItem('signedInUserType');
    const [finalMessageList, setFinalMessageList] = useState<any>([])
    const [mcAlertList, setMcAlertList] = useState<any>([])
    const { getMasterTableLookupValueData, getMasterTableLookupData, getUserGroupTableLookupData, getOrganizationUnitsTableLookupData } = useLooksUpData();
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [contentTypeList, setContentTypeList] = useState<any>([])
    const [reportGroupList, setReportGroupList] = useState<any>([])
    const [reportFileFormat, setReportFileFormat] = useState<any>([])
    const [frequencyLKPCDList, setFrequencyLKPCDList] = useState<any>([])
    const [businessStatusLKPCDList, setBusinessStatusLKPCDList] = useState<any>([])
    const [afStatusLKPCDList, setAfStatusLKPCDList] = useState<any>([])
    const [uploadTypeLKPCDList, setUploadTypeLKPCDList] = useState<any>([])
    const [checklistAfStatusLKPCDList, setChecklistAfStatusLKPCDList] = useState<any>([])
    const [mcoActionLKPCDList, setNcoActionLKPCDList] = useState<any>([])
    const [reviewerGroupLKPCDList, setReviewerGroupLKPCDList] = useState<any>([])
    let [reviewersGroupsList, setReviewersGroupsList] = useState<any>([]);
    let [mcUserGroupsList, setMcUserGroupsList] = useState<any>([]);
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);

    const fetchAllMctFinalMessageData = async () => {
        try {
            setIsLoading(true);
            const response = await ReportInventoryService.getAllMctfinalmessage();
            if (response && response.data) {
                setFinalMessageList(response.data);
            }
        } catch (error) {
            console.log('error', error);
        }
        finally { setIsLoading(false); }
    }

    const fetchMcAlertsDetailsData = async () => {
        try {
            const response = await ReportInventoryService.getAllMcAlertsDetails();
            if (response && response.data) {
                setMcAlertList(response.data);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcReportType = filterAndMapData('AL', 'MC_PROGRAM_REPORT', 'REPORTING_TYPE_LKPCD');
                const mcContentType = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORTING_CONTENT_TYPE_LKPCD');
                const mcReportingGroup = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORTING_GROUP_QLFR_LKPCD');
                const mcFrequencyLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'DUE_DATE_FREQUENCY_LKPCD');
                const mcFileFormatLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORT_FILE_TYPE_LKPCD');
                const mcBusinessStatusLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'BUSINESS_STATUS_LKPCD');
                const mcAfStatusLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'AF_STATUS_LKPCD');
                const mcFileUploadType = filterAndMapData('MC', 'MC_REPORT_FILENAME_TEMPLATE', 'UPLOAD_TYPE_LKPCD');
                const mcChecklistAfStatusLkpcde = filterAndMapData('RM', 'MC_CHECKLIST', 'CHECKLIST_TYPE_LKPCD');
                const mcMcoActionLkpcde = filterAndMapData('MC', 'MC_REPORT_REVIEWERS_GROUP', 'ACTION_LKPCD');
                const mcReviewerGroupLkpcde = filterAndMapData('NG', 'MC_REPORT_REVIEWERS_GROUP', 'AF_STATUS_LKPCD');


                setReportTypeList(mcReportType);
                setContentTypeList(mcContentType);
                setReportGroupList(mcReportingGroup);
                setFrequencyLKPCDList(mcFrequencyLKPCD);
                setReportFileFormat(mcFileFormatLKPCD);
                setBusinessStatusLKPCDList(mcBusinessStatusLKPCD);
                setAfStatusLKPCDList(mcAfStatusLKPCD);
                setUploadTypeLKPCDList(mcFileUploadType);
                setChecklistAfStatusLKPCDList(mcChecklistAfStatusLkpcde)
                setNcoActionLKPCDList(mcMcoActionLkpcde)
                setReviewerGroupLKPCDList(mcReviewerGroupLkpcde)
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcUserGroupTableLookupValueData = async () => {
        try {
            const response = await getUserGroupTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            userGroupCode: `${code.userGroupCode}`,
                            userGroupName: code.userGroupName,
                            userGroupUsage: code.userGroupUsage,
                            oprtnlFlag: code.oprtnlFlag,
                        }));
                };

                setMcUserGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcOrganizationUnitsTableLookupValueData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await fetchGetMasterTableLookupValueData();
                await fetchGetMcUserGroupTableLookupValueData();
                await fetchGetMcOrganizationUnitsTableLookupValueData();
                await fetchAllMctFinalMessageData();
                await fetchMcAlertsDetailsData();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const gridStyle = { minHeight: 622, width: '100%' };
    const [gridRef, setGridRef] = useState(null as any)

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingReportingTypeLkpcd = (data: any) => getMatchingDescription(reportTypeList, data);
    const matchingReportingContentTypeLkpcd = (data: any) => getMatchingDescription(contentTypeList, data);
    const matchingReportingGroupQlfrLkpcd = (data: any) => getMatchingDescription(reportGroupList, data);
    const matchingFrequencyLkpcd = (data: any) => getMatchingDescription(frequencyLKPCDList, data);
    const matchingReportFileTypeLkpcd = (data: any) => getMatchingDescription(reportFileFormat, data);
    const matchingBusinessStatusLkpcd = (data: any) => getMatchingDescription(businessStatusLKPCDList, data);
    const matchingAfStatusLkpcd = (data: any) => getMatchingDescription(afStatusLKPCDList, data);
    const matchingUploadTypeLkpcd = (data: any) => getMatchingDescription(uploadTypeLKPCDList, data);
    const matchingChecklistAfStatusLkpcd = (data: any) => getMatchingDescription(checklistAfStatusLKPCDList, data);
    const matchingMcoActionLkpcd = (data: any) => getMatchingDescription(mcoActionLKPCDList, data);
    const matchingReviewerGroupLkpcd = (data: any) => getMatchingDescription(reviewerGroupLKPCDList, data);

    const getMatchingMcUserGroup = (data: any) => {
        const matchedItem = mcUserGroupsList.find((code: any) => code.userGroupCode === data);
        return matchedItem?.userGroupName || data;
    };

    const getMatchingMcOrgUnit = (data: any) => {
        const matchedItem = mcOrgUnitsList.find((code: any) => code.organizationUnitCode === data);
        return matchedItem?.organizationUnitName || data;
    };

    const getMatchingMcAlert = (data: any) => {
        const matchedItem = mcAlertList.find((code: any) => code.alertId === parseInt(data));
        return matchedItem?.alertTitle || data;
    };

    const getMatchingMcAlertDetails = (data: any) => {
        const matchedItem = mcAlertList.find((code: any) => code.alertId === parseInt(data));
        return matchedItem?.alertDetail || data;
    };

    const filterValue = [
        { name: 'createdDate', operator: 'contains', type: 'string', value: '' },
        { name: 'alertId', operator: 'contains', type: 'string', value: '' },
        { name: 'alertDetails', operator: 'contains', type: 'string', value: '' },
        { name: 'instancePlanId', operator: 'contains', type: 'string', value: '' },
        { name: 'instanceName', operator: 'contains', type: 'string', value: '' },
        { name: 'reportingPeriod', operator: 'contains', type: 'string', value: '' },
    ]

    const remindersListHeaders: any[] = [
        {
            name: 'createdDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
        },
        {
            name: 'alertId',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Alert
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            render: ({ data }: { data: any }) => {
                return getMatchingMcAlert(data.alertId);
            }
        },
        {
            name: 'alertDetails',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Alert Details
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            render: ({ data }: { data: any }) => {
                return getMatchingMcAlertDetails(data.alertId);
            }
        },
        {
            name: 'instancePlanId',
            header:
                (
                    <div className='grid-header-custom-style'>
                        MCO
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            name: 'instanceName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 200,
        },

        {
            name: 'reportingPeriod',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Reporting Period
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
            render: ({ data }: { data: any }) => {
                const beginDate = moment(data.instanceStartDate).format('MM/DD/YYYY');
                const endDate = moment(data.instanceEndDate).format('MM/DD/YYYY');

                return `${beginDate} - ${endDate}`;
            },
        }
    ]

    const mapLookupValues = (item: any) => {
        return {
            ...item,
            alertId: getMatchingMcAlert(item.alertId),
            alertDetails: getMatchingMcAlertDetails(item.alertId),
            reportingPeriod: `${moment(item.instanceStartDate).format('MM/DD/YYYY')} - ${moment(item.instanceEndDate).format('MM/DD/YYYY')}`,
        };
    };
    const mappedData = finalMessageList.map(mapLookupValues);

    const handleRowClick = (data: any) => {
        history.push({
            // pathname: '/contract-report-details',
            state: { recordData: data }
        });
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Reminders and Alerts" />
                </div>
            </div>

            <div className="form-layout">
                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading.....</p>
                    </div>
                ) :
                    <>
                        <ReactDataGrid
                            licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                            idProperty="key"
                            style={gridStyle}
                            pagination
                            columns={remindersListHeaders}
                            dataSource={mappedData}
                            sortable={true}
                            // onFilterValueChange={handleFilterChangeGrid}
                            defaultLimit={10}
                            defaultFilterValue={filterValue}
                            rowHeight={50}
                            // onRowClick={({ data }) => handleRowClick(data)}
                            // className="pointer-cursor-row"
                            enableColumnAutosize={true}
                            handle={setGridRef}
                        />
                    </>
                }
            </div>
            <Footer />
        </>
    );
}
export default Reminders;