import { createContext, useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { useReportInventoryService } from '../src/services/ReportInventoryServices';
import { useLooksUpData } from '../src/utils/getLookupTableData';

const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const { instance, accounts } = useMsal();
  const { getUserGroupTableLookupData } = useLooksUpData();
  const [globalVariable, setGlobalVariable] = useState({});
  const [signedInUserGroupsList, setSignedInUserGroupsList] = useState([]);

  useEffect(() => {
    if (accounts && accounts[0] && accounts[0].localAccountId) {
      getGroups();
    }
  }, [accounts]);

  async function fetchAllMemberGroups(url, accessToken, groups = []) {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    const newGroups = groups.concat(data.value);
    if (data['@odata.nextLink']) {
      return fetchAllMemberGroups(data['@odata.nextLink'], accessToken, newGroups);
    } else {
      return newGroups;
    }
  }

  const getGroups = async () => {
    const account = accounts[0];
    const tokenRequest = {
      scopes: ['User.Read', 'Group.Read.All'],
      account: account
    };
    try {
      const response = await instance.acquireTokenSilent(tokenRequest);
      if (response) {
        const accessToken = response.accessToken;
        sessionStorage.setItem('msalToken', accessToken);

        const allGroupsFromAzure = await fetchAllMemberGroups('https://graph.microsoft.com/v1.0/me/memberOf', accessToken);

        const groupDisplayNames = allGroupsFromAzure.map((group) => group.displayName);
        const pagePermissions = {};
        signedInUserGroupsList.forEach((permission) => {
          const groupDisplayNamesCase = groupDisplayNames.map(el => el.toLowerCase());
          const isWriteForPage = groupDisplayNamesCase.indexOf(permission.value?.toLowerCase()) > -1;
          pagePermissions[permission.value] = isWriteForPage;
        });
        setGlobalVariable(pagePermissions);
      }
    } catch (error) {
      console.error('Error acquiring token silently:', error);
    }
  };

  return (
    <GlobalContext.Provider value={{ globalVariable, setGlobalVariable, setSignedInUserGroupsList }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };